import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";
import { useHistory, useParams } from "react-router-dom";
import ApiEndpoints from "../api/ApiEndpoints";
import { setAppPageTitle, LogDebug, LogError } from "../utils/Utils";
import Constants from "../utils/Constants";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { GlobalStyle, classes } from "../theme/GlobalStyle";
import Loading from "../utils/utilcomponents/Loading";
import BoardContainer from "../containers/BoardContainer";
import { isEmpty } from "lodash";

const RootDiv = styled("div")(({ theme }) => GlobalStyle(theme));

const areEqual = (prevProps, nextProps) => {
	let result = false;

	if (!prevProps?.boardData?.board || isEmpty(prevProps.boardData.board)) result = false;
	else if (prevProps?.authData?.is_public === undefined) result = false;
	else if (prevProps?.authData?.is_public !== nextProps?.authData?.is_public) result = false;
	else if (prevProps?.boardData?.board?.boardid === nextProps?.boardData?.board?.boardid) result = true;

	if (result) {
		LogDebug("homepage did not render areEqual check=" + result);
	} else LogDebug("homepage rendered = areEqual check=" + result);

	return result;
};

const HomePage = ({ authData, boardData, doApiCall, doClearAllAuthData, doUpdateThemeType }) => {
	const [busy, setBusy] = useState(false);
	const { is_public, token, boardid: storedBoardID, campusid: storedCampusID } = authData;
	const { board } = boardData;

	const routerHistory = useHistory();
	const theme = useTheme();

	const { inputcampusid, inputboardid } = useParams(); //from url
	const [campusID, setCampusID] = useState(parseInt(inputcampusid) || 0);
	const [boardID, setBoardID] = useState(parseInt(inputboardid) || 0);

	const doGetBoardAPI = useCallback(
		(includeBusy) => {
			if (campusID === 0 || boardID === 0) return;

			doApiCall(
				ApiEndpoints.GetBoard,
				{ campusid: campusID, boardid: boardID },
				includeBusy ? setBusy : () => {},
				{ logRequest: true, logResponse: true, hideErrorToast: true },
				() => {
					LogDebug("Success get board.");
				},
				(errorMsg) => {
					//todo log out might be triggering this call
					LogError("Failed to get board. " + errorMsg);
				}
			);
		},
		[campusID, boardID]
	);

	// useInterval(() => {
	// 	doGetBoardAPI();
	// }, Constants.refreshBoardDataInSeconds * 1000);
	//refresh the data every 60 seconds?
	//this could depend on what kind of data they have
	useEffect(() => {
		let id = setInterval(doGetBoardAPI, Constants.refreshBoardDataInSeconds * 1000);
		return () => clearInterval(id);
	}, []);

	useEffect(() => {
		LogDebug(`useEffect home board`);
		if (!board) setAppPageTitle("");
		else setAppPageTitle(board.name);

		if (!board) return;

		const boardThemeType = board?.theme_type === 1 ? Constants.themeDarkString : Constants.themeLightString;
		if (theme.palette.mode === boardThemeType) return;
		//dont update theme unless it is different. avoids causing a re-render
		LogDebug(`useEffect home doUpdateThemeType`);

		doUpdateThemeType(board?.theme_type === 1 ? Constants.themeDarkString : Constants.themeLightString);
	}, [board]);

	//step 1, determine whether it is private or public.
	//This sets the status of public / private in redux
	useEffect(() => {
		LogDebug(`useEffect home initalize`);

		if (campusID === 0 || boardID === 0) return; //dont bother since nothing provided

		if (storedCampusID && storedBoardID && (campusID !== storedCampusID || boardID !== storedBoardID)) {
			LogDebug("boardid/campusid in settingsData is different than the input ones, so clear the ones that are stored");
			doClearAllAuthData();
		}
	

		const doGetAccessTypeAPI = () => {
			doApiCall(
				ApiEndpoints.GetAccessType,
				{ campusid: campusID, boardid: boardID },
				setBusy,
				{ logRequest: true, logResponse: true, hideErrorToast: true, title: "Board Access Type" },
				(data) => {
					LogDebug("Success accessType - is_public=" + data?.access?.is_public);
				},
				(errorMsg, errorReason) => {
					//TODO log out might be triggering this call
					if (errorReason === "BOARD") {
						setBoardID(0);
						setCampusID(0);
						//will force redirect since board not found
					}
				}
			);
		};
		doGetAccessTypeAPI();
	}, []);

	useEffect(() => {
		LogDebug(`useEffect campusID=${campusID}  boardID=${boardID}`);
		if (campusID === 0 && boardID === 0) {
			//redirect here since board credentials not entered
			routerHistory.replace(`/${Constants.invalidPageUrl}`);
			// setShouldGoToInvalidPage(true);
		}
	}, [boardID, campusID]);

	//step 2, get the baord if public, otherwise require the authentication by sending the user to the authenticate page
	useEffect(() => {
		LogDebug("useEffect is_public triggered = " + is_public);
		//this api call will pass the token in the header if it has it.
		//if isPrivate, then we need to check if they have a token and attempt login,
		if (is_public === undefined) return;

		if (is_public || (is_public === false && token?.length > 0)) doGetBoardAPI(true);
		else routerHistory.replace(`/${campusID}/${boardID}/${Constants.authPageUrl}`);
	}, [is_public, token]);

	//wait for the theme to load to avoid strange color mismatch bug
	if (!theme.isThemeLoaded) return <></>;

	//if campusid and boardid are not supplied, then invalid url
	if (campusID === 0 || boardID === 0) return <></>;

	//show loading while we are initializing the board public status and board obj
	if (busy || is_public === undefined) return <Loading />;

	if (!board) return <Loading />;

	LogDebug("HomePage view rendered");

	return (
		<RootDiv className={classes.homePageContainer}>
			<BoardContainer classes={classes} />
		</RootDiv>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(React.memo(HomePage, areEqual));

//the home page handle the navigation based on the url and the api calls to get the board, etc.
//once it has validated everything it passed it off to the BoardContainer to manages the display
