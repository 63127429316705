import React, { useState, useEffect } from "react";

const useContainerDimensions = (myRef) => {
	const getDimensions = () => ({
		width: myRef.current.offsetWidth,
		height: myRef.current.offsetHeight,
	});

	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	useEffect(() => {
		const handleResize = () => {
			setDimensions(getDimensions());
		};

		if (myRef.current) {
			setDimensions(getDimensions());
		}

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [myRef]);

	return dimensions;
};

export const useContainerDimensionsVariableHeader = (myRef) => {
	const getDimensions = () => {
		const bodyWidth = myRef[0].offsetWidth;
		const bodyHeight = myRef[0].offsetHeight;
		const headerHeight = myRef[1]?.offsetHeight ;
		
		return {
		width: bodyWidth,
		height: bodyHeight - headerHeight,
	}
};

	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	useEffect(() => {
		const handleResize = () => {
			setDimensions(getDimensions());
		};

		if (myRef) {
			setDimensions(getDimensions());
		}

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [myRef]);

	return dimensions;
};

export default useContainerDimensions;
