import { LogDebug } from "../utils/Utils";
import { isAramark, getImageFromURL } from "../utils/EnvUtils";
import EnvConfig from "../utils/EnvConfig";
import Constants from "../utils/Constants";
import { getObjectFromArray } from "../utils/ObjectUtils";
import { ScreenLayoutOptions, ScreenLayoutTypes } from "../utils/BoardUtils";

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { getDateObjFromUTCString } from "../utils/DateUtils";
dayjs.extend(isSameOrAfter);

export const processArray = (array, stateData, processIndividualFx) => {
	if (!array) return;
	array.forEach((obj) => {
		processIndividualFx(obj, stateData);
	});
};

export const processBoard = (b) => {
	if (!b) return;
	b.id = b.boardid;

	b.status = "";
	b.status_int = 99;
	if (b.is_disabled === 1) {
		b.status = "Disabled";
		b.status_int = 5;
	} else {
		b.status = "Active";
		b.status_int = 1;
	}

	b.number_of_screens = b?.screens?.filter((s) => s.is_disabled === 0).length;

	b?.screens?.forEach((s, idx) => {
		processBoardScreen(s);
		s.index = idx;
	});
};

export const processBoardScreen = (s) => {
	if (!s) return;
	s.id = s.screenid;

	s.status = "";
	s.status_int = 99;
	if (s.is_disabled === 1) {
		s.status = "Hidden";
		s.status_int = 5;
	} else {
		s.status = "Visible";
		s.status_int = 1;
	}

	s.type_name = "N/A";
	// if (s.layout_type > 0) {
	// 	const currentType = getPickerOptionFromArray(ScreenLayoutOptions, s.layout_type || ScreenLayoutOptions[0].value);
	// 	s.type_name = currentType ? currentType.label : s.layout_type;
	// }

	s?.views?.forEach((v) => {
		processBoardScreenView(v);
	});
};

export const processBoardScreenView = (v) => {
	if (!v) return;
	v.id = v.viewid;

	v.type_name = "";
	processLocations(v.locations);
	processOrders(v.orders, v.locations);
};

const processLocations = (locations) => {
	if (!locations) return;

	locations.forEach((l) => {
		l.icon_picture_url_with_base = Constants.placeholderFood;
		if (l.icon_picture_url !== "") l.icon_picture_url_with_base = EnvConfig.baseImageURL + l.icon_picture_url;

		if (l.menu) processMenu(l.menu);
	});
};

const processOrders = (orders, locations) => {
	if (!locations || !orders) return;

	orders.forEach((o) => {
		const loc = getObjectFromArray(o.locationid, locations, "locationid");
		o.location_name = loc?.name || "";
		o.location_icon = loc?.icon_picture_url_with_base || "";

		o.order_moment = getDateObjFromUTCString(o.order_datetime);
		o.preferred_moment = getDateObjFromUTCString(o.preferred_datetime).add(o.kitchen_adjustment_minutes);

		if (o?.released_datetime.length > 0) o.released_moment = getDateObjFromUTCString(o.released_datetime);

		if (o?.complete_datetime.length > 0) o.complete_moment = getDateObjFromUTCString(o.complete_datetime);

		o.display_text = "";
		if (o?.requires_checkin === 1) o.display_text = "Please Check-In";
		else if (o?.asap === 0) o.display_text = o.preferred_moment.format("h:mm A");

		if (o?.complete_datetime.length > 0) o.display_text = "Ready!";
	});
};

const processMenu = (m) => {
	if (!m) return;
	m.id = m.locationid;

	let all_items = [];
	m.sections_1.forEach((sec) => {
		sec.id = sec.sectionid;

		sec.cover_picture_url_with_base = ""; // Constants.placeholderFoodRectangle;
		if (sec?.cover_picture_url?.length > 0) sec.cover_picture_url_with_base = EnvConfig.baseImageURL + sec.cover_picture_url;

		sec.icon_picture_url_with_base = ""; //Constants.placeholderFood;
		if (sec?.icon_picture_url?.length > 0) sec.icon_picture_url_with_base = EnvConfig.baseImageURL + sec.icon_picture_url;

		for (let x = 0; x < sec.items.length; x++) {
			const item = sec.items[x];
			item.id = item.itemid;

			item.cover_picture_url_with_base = Constants.placeholderFoodRectangle2; //show a placeholder in this situation
			if (item?.cover_picture_url?.length > 0) item.cover_picture_url_with_base = EnvConfig.baseImageURL + item.cover_picture_url;

			//if icon exists use that, if not uyse the cover image, otherwise the placeholder
			if (item?.icon_picture_url?.length > 0) item.icon_picture_url_with_base = EnvConfig.baseImageURL + item.icon_picture_url;
			else if (item.cover_picture_url.length > 0) item.icon_picture_url_with_base = item.cover_picture_url_with_base;
			else item.icon_picture_url_with_base = Constants.placeholderFood;

			for (let optionIdx = 0; optionIdx < item?.options.length; optionIdx++) {
				const option = item.options[optionIdx];
				option.id = option.optionid;
				for (let valueIdx = 0; valueIdx < option?.values.length; valueIdx++) {
					option.values[valueIdx].id = option.values[valueIdx].valueid;
				}
			}

			//max 6 icons
			const dietary_restrictions =
				item?.dietary_restrictions?.length > 6 ? item?.dietary_restrictions.splice(0, 6) : item.dietary_restrictions;

			dietary_restrictions?.forEach((d) => processDietaryRestriction(d));
			item.dietary_restrictions = dietary_restrictions;

			if (item.is_deleted === 0) all_items.push(item);
		}
	});

	m.all_items = all_items;
};

export const processDietaryRestriction = (d) => {
	if (!d) return;

	if (d.icon_url?.length > 0) d.icon_url_with_base = EnvConfig.baseImageURL + d.icon_url;
	else d.icon_url_with_base = Constants.placeholderFood;
};
