import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import CustomFormStyles from "./CustomFormStyles";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

const useStyles = makeStyles((theme) => {
	return CustomFormStyles(theme);
});

const CustomFormErrorAlert = ({ setFormErrorMessage, formErrorMessage, theID, marginTop, marginBottom, width }) => {
	const classes = useStyles();
	const theStyle = { marginTop, marginBottom, width: width };

	if (!formErrorMessage || formErrorMessage.length === 0) return <></>;

	return (
		<Alert
			classes={{ root: classes.alertError, action: classes.alertActionButton }}
			style={theStyle}
			id={theID}
			severity="error"
			variant="filled"
			onClose={() => {}}
			onClick={(e) => {
				e && e.preventDefault();
				setFormErrorMessage("");
			}}
		>
			<Typography variant="body2" display="block">
				{formErrorMessage}
			</Typography>
		</Alert>
	);
};

export default CustomFormErrorAlert;
