const Constants = {
	refreshBoardDataInSeconds: 60,
	globalNumCols: 100,
	viewNumCols: 60,

	invalidPageUrl: "boardnotfound",
	authPageUrl: "authentication",

	uuidCookieName: "hangry_uuid",
	sessionidCookieName: "hangry_sessionid",
	loginHashKey: "mRkEEt6QGRDRf5FRQ55yXv7",
	initialVisitStorageName: "createdon",

	localString: "Local",
	developmentString: "Development",
	productionString: "Production",
	themeDarkString: "dark",
	themeLightString: "light",

	dateFormatMonthDay: "MMMM Do",
	dateFormatWithDOW: "dddd, MMMM Do",
	timeFormat: "h:mm A",
	isoWeekFormat: "isoWeek",
	startDayFormat: "day",

	placeholderProfilePicture: "/images/placeholders/profile_placeholder.jpg",
	placeholderFood: "/images/placeholders/food_placeholder.jpg",
	placeholderFoodRectangle: "/images/placeholders/food_placeholder_rectangle.jpg",
	placeholderFoodRectangle2: "/images/placeholders/food_placeholder_rectangle2.jpg",
	placeholderBuilding: "/images/placeholders/building_placeholder.jpg",
	placeholderSchool: "/images/placeholders/school_placeholder.jpg",
	placeholderTicket: "/images/placeholders/ticket_placeholder.jpg",
	placeholderStar: "/images/placeholders/star_placeholder.jpg",
};

export default Constants;
