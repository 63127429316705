import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";
import clsx from "clsx";
import { LogDebug } from "../utils/Utils";
import Constants from "../utils/Constants";
import { Grid, Typography } from "@mui/material";
import { getMinutesBetweenDates,  getNowDate } from "../utils/DateUtils";
import useInterval from "../utils/hooks/useInterval";
import useContainerDimensions from "../utils/hooks/useContainerDimensions";
import { getOrdersFromBoardData } from "../utils/BoardUtils";

const getMinutesDifferenceFromNow = (inputDate) => {
	if (!inputDate) return 99999999;
	const now = getNowDate();
	const mins = getMinutesBetweenDates(now, inputDate);
	return mins;
};

const getMinsDisplay = (mins, dummy) => {
	if (mins < 0) return `${mins * -1} mins`;
	if (mins === 99999999 || !dummy) return "";
	if (mins > 0) return `In Progress`;
};

const numCols = 60; //60 divisible by 1 to 6

//need to play with breakpoints here to get it right
const getColWidth = (width) => {
	if (width <= 0) return numCols; //1 col
	if (width < 600) return numCols; //1 col
	if (width < 900) return numCols / 2; //2 cols
	if (width < 1200) return numCols / 3; //3 cols
	if (width < 1500) return numCols / 4; //4 cols
	if (width < 1800) return numCols / 5; //5 cols
	return numCols / 6; //6 cols
};

const OrderStatusGrid = ({ classes, boardData, view }) => {
	const [time, setTime] = useState(Date.now());
	const gridRef = useRef();
	const { width, height } = useContainerDimensions(gridRef);
	//since this component can be a % width of the overall screen, we cant rely on the Grid item column sizing since it is
	//calculated based on the overall screen width instead of the components width

	useInterval(() => {
		setTime(Date.now());
	}, 10 * 1000);
	//update the minutes display / refresh the view every 10 seconds

	const orders = getOrdersFromBoardData(boardData, view)

	if (!orders) return <></>;

	const colWidth = getColWidth(width);
	const singleRow = numCols / colWidth >= orders?.length;

	return (
		<Grid ref={gridRef} container columns={numCols} spacing={"1.0rem"} key={"" + time} justifyContent={singleRow ? "center" : undefined}>
			{width > 0 &&
				orders.map((o, idx) => {
					const mins = getMinutesDifferenceFromNow(o.preferred_moment);
					const minsText = getMinsDisplay(mins, time);
					const displayText = o.display_text.length > 0 ? o.display_text : minsText;

					return (
						<Grid item key={idx} xs={colWidth}>
							<div
								className={clsx(classes.orderStatusCellDiv, {
									[classes.orderCompleteRecentlyCellDiv]: o.recently_completed === 1,
								})}
							>
								<Typography
									component={"div"}
									className={classes.orderNumberDisplay}
								>{`#${o.orderid_short_string}`}</Typography>
								{o.first_name && (
									<Typography component={"div"} className={classes.orderUserNameDisplay}>{`${o.first_name}`}</Typography>
								)}
								<Typography component={"div"} className={classes.orderStatusDisplay}>{`${displayText}`}</Typography>
							</div>
						</Grid>
					);
				})}
		</Grid>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(OrderStatusGrid);
