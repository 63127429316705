import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";
import { useHistory, useParams } from "react-router-dom";
import ApiEndpoints from "../api/ApiEndpoints";
import Constants from "../utils/Constants";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { GlobalStyle, classes } from "../theme/GlobalStyle";
import { getLogoImgBasedOnEnv } from "../utils/EnvUtils";
import { getHashedChecksumForApiCall, LogDebug, setAppPageTitle } from "../utils/Utils";
import ValidateInput from "../utils/ValidatorUtils";

import FooterView from "./FooterView";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CustomFormPassword from "../utils/utilcomponents/CustomFormPassword";
import CustomFormErrorAlert from "../utils/utilcomponents/CustomFormErrorAlert";

const RootDiv = styled("div")(({ theme }) => GlobalStyle(theme));

const AuthenticationPage = ({ authData, settingsData, doApiCall, doUpdateThemeType }) => {
	const { is_public, board_name, campus_name, theme_type } = authData;
	const routerHistory = useHistory();
	const theme = useTheme();

	const { inputcampusid, inputboardid } = useParams(); //from url
	const [campusID, setCampusID] = useState(parseInt(inputcampusid) || 0);
	const [boardID, setBoardID] = useState(parseInt(inputboardid) || 0);

	const [busy, setBusy] = useState(false);
	const [formErrorMessage, setFormErrorMessage] = useState("");

	const [password, setPassword] = useState("");
	const [errorPassword, setErrorPassword] = useState("");

	useEffect(() => {
		if (is_public === undefined) {
			LogDebug("useEffect PasswordPage is_public undefined triggered redirect");
			routerHistory.replace(`/${campusID}/${boardID}`);
			return;
		}

		setAppPageTitle("Authentication - " + board_name);
	}, []);

	useEffect(() => {
		LogDebug(`useEffect auth theme`);
		if (theme_type === undefined) return;

		const boardThemeType = theme_type === 1 ? Constants.themeDarkString : Constants.themeLightString;
		if (theme.palette.mode === boardThemeType) return;
		//dont update theme unless it is different. avoids causing a re-render
		LogDebug(`useEffect auth doUpdateThemeType`);

		doUpdateThemeType(theme_type === 1 ? Constants.themeDarkString : Constants.themeLightString);
	}, [theme_type]);

	const loginClicked = (e) => {
		e && e.preventDefault();
		setFormErrorMessage("");
		if (!ValidateInput(password, setErrorPassword, { minLength: 5, maxLength: 50 })) return;

		const hashChecksum = getHashedChecksumForApiCall(campusID + boardID + password + campusID + boardID, Constants.loginHashKey);
		const reqBody = {
			boardid: boardID,
			campusid: campusID,
			password: password,
			hash: hashChecksum,
		};

		setBusy(true);

		doApiCall(
			ApiEndpoints.LoginWithPassword,
			reqBody,
			undefined,
			{ logRequest: true, logResponse: true, hideErrorToast: true, setFormErrorMessage: setFormErrorMessage },
			() => {
				setBusy(false);
				routerHistory.push(`/${campusID}/${boardID}`); //goto home
			},
			(errorMsg) => {
				setBusy(false);
			}
		);
	};

	//const theLogo = getLogoImgBasedOnEnv(themeType);

	if (is_public === undefined) return <></>;

	return (
		<RootDiv className={classes.authPageContainer}>
			<Backdrop className={classes.authBackdrop} open={busy} onClick={() => {}}>
				<CircularProgress aria-label="Attempting auto login. Please wait..." color="secondary" size={100} />
			</Backdrop>
			{/* <div role={"heading"} aria-level={"1"} aria-label={"Digital Board Display for Mobile Order"} tabIndex="0">
				<img src={theLogo} aria-label="Digital Board Logo" className={classes.logoImage} />
			</div> */}
			<Typography className={classes.authHeaderText} variant="h1" display="block" color={"textPrimary"}>
				{board_name}
			</Typography>
			<Typography className={classes.authHeaderSubText} variant="subtitle1" display="block" color={"textPrimary"}>
				{campus_name}
			</Typography>
			<form
				className={classes.authForm}
				noValidate
				autoComplete="on"
				id={"login-form"}
				aria-describedby={formErrorMessage.length > 0 ? "login-form-error" : undefined}
				onSubmit={(e) => {
					e && e.preventDefault();
					loginClicked();
				}}
			>
				<CustomFormPassword
					inputID={"password"}
					inputLabel={"Password"}
					setValue={setPassword}
					value={password}
					setError={setErrorPassword}
					errorText={errorPassword}
					maxLength={50}
					marginBottom={50}
					isAutoFocus
					labelAlwaysAtTop
				/>

				<Button
					variant="contained"
					color="secondary"
					size="large"
					className={classes.authButton}
					fullWidth
					disabled={busy}
					disableElevation
					onClick={loginClicked}
				>
					LOG IN
				</Button>

				<CustomFormErrorAlert
					theID={"login-form-error"}
					setFormErrorMessage={setFormErrorMessage}
					formErrorMessage={formErrorMessage}
					marginTop={50}
				/>
			</form>
			<FooterView classes={classes} themeType={theme_type} />
		</RootDiv>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(AuthenticationPage);
