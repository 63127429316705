import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { LogDebug } from "../utils/Utils";
import { ViewTypes } from "../utils/BoardUtils";

import OrderStatusGrid from "../views/OrderStatusGrid";
import OrderCompleteList from "../views/OrderCompleteList";
import ImageCarousel from "../views/ImageCarousel";
import VideoCarousel from "../views/VideoCarousel";
import LocationCheckInQRCode from "../views/LocationCheckInQRCode";
import LocationWaitTimes from "../views/LocationWaitTimes";
import IFrameHtml from "../views/IFrameHtml";
import IFrameURL from "../views/IFrameURL";
import { Typography } from "@mui/material";
import LocationMenu from "../views/LocationMenu";

const HeadingView = ({ classes, view: v, bodyDivRef }) => {
	if (!v) return <></>;
	if (v?.heading.length === 0 && v?.subheading.length === 0) return <></>;

	return (
		<div className={classes.viewHeadingDiv} ref={(el) => (bodyDivRef.current[1] = el)}>
			{v?.heading?.length > 0 && <Typography variant="h3" component={"div"} className={classes.viewHeading}>{`${v?.heading}`}</Typography>}
			{v?.subheading?.length > 0 && (
				<Typography variant="subtitle1" component={"div"} className={classes.viewSubHeading}>{`${v?.subheading}`}</Typography>
			)}
		</div>
	);
};

const ViewContainer = ({ classes, view, screen }) => {
	const bodyDivRef = useRef([]);
	const [v, setV] = useState(view);

	useEffect(() => {
		if (!view) setV(view);
		if (!v) setV(view);

		if (!view) return;
		
		//these view types do not need to change
		//it just the orders / locations that change
	}, [view]);

	const view100Height = v?.view_type === ViewTypes.kImageCarousel;
	const viewInheritHeight =
		v?.view_type === ViewTypes.kVideoCarousel ||
		v?.view_type === ViewTypes.kIframeUrl ||
		v?.view_type === ViewTypes.kIframeHtml ||
		v?.view_type === ViewTypes.kLocationMenu;

	const viewOutterPaddingHalf =
		v?.view_type === ViewTypes.kOrderStatusGrid ||
		v?.view_type === ViewTypes.kOrderCompleteList ||
		v?.view_type === ViewTypes.kLocationWaitTimes;

	const ViewWrapper = ({ children }) => (
		<div className={classes.viewParentDiv} ref={(el) => (bodyDivRef.current[0] = el)}>
			<HeadingView classes={classes} view={v} bodyDivRef={bodyDivRef} />
			<div
				className={clsx(classes.viewInnerDiv, {
					[classes.viewInnerDivFlex]: true,
					[classes.viewInnerDivPaddingHalf]: viewOutterPaddingHalf,
					[classes.viewInnerDivInheritHeight]: viewInheritHeight,
					[classes.viewInnerDiv100Height]: view100Height,
				})}
			>
				{children}
			</div>
		</div>
	);

	const baseViewProps = { view: v, classes: classes };

	LogDebug("ViewContainer view rendered - " + v?.name);

	if (!v) return <>VIEW NOT SET</>;

	if (v.view_type === ViewTypes.kOrderStatusGrid) {
		return (
			<ViewWrapper>
				<OrderStatusGrid {...baseViewProps} />
			</ViewWrapper>
		);
	}

	if (v.view_type === ViewTypes.kOrderCompleteList) {
		return (
			<ViewWrapper>
				<OrderCompleteList {...baseViewProps} />
			</ViewWrapper>
		);
	}

	if (v.view_type === ViewTypes.kLocationWaitTimes) {
		return (
			<ViewWrapper>
				<LocationWaitTimes  {...baseViewProps} />
			</ViewWrapper>
		);
	}

	if (v.view_type === ViewTypes.kLocationCheckinQRCode) {
		return (
			<ViewWrapper>
				<LocationCheckInQRCode  {...baseViewProps} bodyDivRef={bodyDivRef} />
			</ViewWrapper>
		);
	}

	if (v.view_type === ViewTypes.kLocationMenu) {
		return (
			<ViewWrapper>
				<LocationMenu {...baseViewProps} bodyDivRef={bodyDivRef} screen={screen} />
			</ViewWrapper>
		);
	}

	if (v.view_type === ViewTypes.kIframeHtml) {
		return (
			<ViewWrapper>
				<IFrameHtml {...baseViewProps} />
			</ViewWrapper>
		);
	}

	if (v.view_type === ViewTypes.kIframeUrl) {
		return (
			<ViewWrapper>
				<IFrameURL {...baseViewProps} />
			</ViewWrapper>
		);
	}

	if (v.view_type === ViewTypes.kImageCarousel) {
		return (
			<ViewWrapper>
				<ImageCarousel {...baseViewProps} />
			</ViewWrapper>
		);
	}

	if (v.view_type === ViewTypes.kVideoCarousel) {
		return (
			<ViewWrapper>
				<VideoCarousel {...baseViewProps} />
			</ViewWrapper>
		);
	}

	return <ViewWrapper>{v.name}</ViewWrapper>;
};

export default ViewContainer;

//individual view that will call specific viewTypeComponent based on the view type
