export const getMenuSectionsFromMenu = (menu) => {
	if (!menu || !menu.sections_1) return [];
	return menu.sections_1.filter((s) => {
		const items = getMenuItemsFromMenuSection(s);
		return s.is_deleted === 0 && s.is_hidden === 0 && items?.length > 0;
	});
};

export const getMenuItemsFromMenuSection = (menuSection) => {
	if (!menuSection || !menuSection.items) return [];
	return menuSection.items.filter((i) => i.is_deleted === 0 && i.is_hidden === 0 && i.manual_online === 1);
};
