import AllReducers from "./Reducers";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import reduxThunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";
import { v4 as uuidv4 } from "uuid";
import ApiMiddleware from "./../api/ApiMiddleware";
import * as Actions from "./Actions";
import { LogDebug, getHashedPassword, LogError } from "./../utils/Utils";
import { saveCookie, readCookie } from "./../utils/CookieUtils";
import { isBoardLocal } from "./../utils/EnvUtils";
import Constants from "../utils/Constants";

const uuidCookieName = Constants.uuidCookieName;
let cookieHttpsSecureFlag = true;
if (isBoardLocal()) {
	cookieHttpsSecureFlag = false;
}

//the UUID is used to decrypt the persist store
let theUUID = readCookie(uuidCookieName);
if (!theUUID || theUUID === undefined || theUUID === null || theUUID.length === 0) {
	theUUID = uuidv4();
	//LogDebug("UUID - new - " + theUUID);
} else {
	//LogDebug("UUID - same - " + theUUID);
}

saveCookie(uuidCookieName, theUUID, 14 * 24 * 60); //initial 14 days

let initialVisitTimestamp = localStorage.getItem(Constants.initialVisitStorageName); //only do this the very first time they visit the app
if (!initialVisitTimestamp || initialVisitTimestamp === undefined || initialVisitTimestamp === null || initialVisitTimestamp.length === 0) {
	initialVisitTimestamp = "" + (new Date().getTime() + 1);
	localStorage.setItem(Constants.initialVisitStorageName, initialVisitTimestamp);
	//LogDebug("initialVisitTimestamp - new - " + initialVisitTimestamp);
} else {
	//LogDebug("initialVisitTimestamp - same - " + initialVisitTimestamp);
}

const key = getHashedPassword(theUUID, initialVisitTimestamp).substring(0, 50);
const encryptor = encryptTransform({
	secretKey: key,
	onError: function (error) {
		LogError("encryptor error: " + error); //hide this later, no need to log
	},
});

//https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#actionsanitizer--statesanitizer
///https://github.com/zalmoxisus/redux-devtools-extension
const composeEnhancers = composeWithDevTools({
	Actions,
	trace: true,
	traceLimit: 50,
	actionsDenylist: [Actions.SNACKBAR_ENQUEUE, Actions.SNACKBAR_CLOSE, Actions.SNACKBAR_REMOVE, Actions.SNACKBAR_CLEAR_HISTORY, Actions.API],
});

const composeEnhancersWithMiddleWare = composeEnhancers(applyMiddleware(reduxThunk, ApiMiddleware));

const persistConfig = {
	key: "hangry",
	storage,
	stateReconciler: autoMergeLevel2,
	whitelist: ["authData", "settingsData"], // ["authData", "boardData", "settingsData", "snackbarData"],
	transforms: [encryptor],
};
const persistedReducer = persistReducer(persistConfig, AllReducers);

export const Store = createStore(persistedReducer, {}, composeEnhancersWithMiddleWare);
export const Persistor = persistStore(Store);
