import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { LogDebug } from "../utils/Utils";
import VideoView from "./VideoPlayer";

const getArrayOfVideoUrls = (v) => {
	let array = [];
	if (!v) return array;

	if (v.extra_string1.length > 0) array.push({ url: v.extra_string1, duration: v.extra_int1 });
	if (v.extra_string2.length > 0) array.push({ url: v.extra_string2, duration: v.extra_int2 });
	if (v.extra_string3.length > 0) array.push({ url: v.extra_string3, duration: v.extra_int3 });
	if (v.extra_string4.length > 0) array.push({ url: v.extra_string4, duration: v.extra_int4 });
	if (v.extra_string5.length > 0) array.push({ url: v.extra_string5, duration: v.extra_int5 });
	if (v.extra_string6.length > 0) array.push({ url: v.extra_string6, duration: v.extra_int6 });

	return array;
};

const getNextIndex = (array, currentIndex) => {
	if (!array || array.length === 0 || currentIndex === -1) return -1;
	//at the end of array so return the first index
	if (array.length - 1 === currentIndex) return 0;
	else return currentIndex + 1;
};

const VideoCarousel = ({ classes, view: v }) => {
	const [urlsArray, setUrlsArray] = useState(() => getArrayOfVideoUrls(v));
	const [currentIndex, setCurrentIndex] = useState(() => {
		if (!urlsArray.length === 0) return -1;
		else return 0;
	});

	useEffect(() => {
		if (!v) return;
		if (urlsArray.length === 0) {
			setUrlsArray(getArrayOfVideoUrls(v));
		}
	}, [v]);

	const doGoToNextVideo = () => {
		LogDebug("doGoToNextVideo called");
		if (!urlsArray || urlsArray.length === 0 || urlsArray.length === 1) return;

		const newIndex = getNextIndex(urlsArray, currentIndex);
		setCurrentIndex(newIndex);
	};

	if (!v || urlsArray.length === 0) return <></>;
	//LogDebug(urlsArray);
	//LogDebug(currentIndex);

	const videoObj = currentIndex < urlsArray.length ? urlsArray[currentIndex] : undefined;

	if (!videoObj) return <></>;

	return <VideoView url={videoObj?.url} doGoToNextVideo={doGoToNextVideo} classes={classes} />;
};

export default VideoCarousel;
