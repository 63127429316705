import { Log } from "./Utils";

//standard method for most backend Objects
export const getObjectFromArray = (id, thearray, key) => {
	if (!thearray || !id || !key) return undefined;

	const targetID = parseInt(id) || 0;
	for (let i = 0; i < thearray.length; i++) {
		if (targetID === thearray[i][key]) return thearray[i];
	}
	return undefined;
};

