import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";
import { styled } from "@mui/material/styles";
import { GlobalStyle, classes } from "../theme/GlobalStyle";
import { getLogoImgBasedOnEnv } from "../utils/EnvUtils";
import Typography from "@mui/material/Typography";
import { setAppPageTitle } from "../utils/Utils";
import FooterView from "./FooterView";

const RootDiv = styled("div")(({ theme }) => GlobalStyle(theme));

const InvalidBoardPage = ({ settingsData }) => {
	const themeType = settingsData.themeType;

	useEffect(() => {
		setAppPageTitle("Board Not Found");
	}, []);

	const theLogo = getLogoImgBasedOnEnv(themeType);

	return (
		<RootDiv className={classes.invalidPageContainer}>
			<div role={"heading"} aria-level={"1"} aria-label={"Digital Board Display for Mobile Order"} tabIndex="0">
				<img src={theLogo} aria-label="Digital Board Logo" className={classes.logoImage} />
			</div>
			<Typography variant="h3" component="div" style={{ marginTop: 50 }}>
				PLEASE ENTER A VALID BOARD URL!
			</Typography>

			<FooterView classes={classes} themeType={themeType} />
		</RootDiv>
	);
};

//could have this include a campusID and boardID input to navigate to a correct page / recover

export default connect(defaultMapStateToProps, defaultActionsToProps)(InvalidBoardPage);
