import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOffOutlined";
import Tooltip from "@mui/material/Tooltip";

const CustomFormPassword = ({
	inputID,
	inputLabel,
	setValue,
	value,
	setError,
	errorText,
	placeholderText,
	labelAlwaysAtTop,
	setDirtyBit,
	isDisabled,
	helperText,
	marginTop,
	marginBottom,
	maxLength,
	allowAutoComplete,
	isAutoFocus,
	isOptional,
}) => {
	const theStyle = { marginTop, marginBottom };

	const theMaxLength = maxLength && maxLength > 0 ? maxLength : 1000;

	const autoCompleteProps = allowAutoComplete ? { autoComplete: "password" } : { autoComplete: "off", "data-lpignore": "true" };
	const subText = errorText ? errorText : helperText;

	const handleMouseDownPassword = (e) => {
		e && e.preventDefault();
	};

	const handleOnBlur = (e) => {
		e && e.preventDefault();
	};

	const handleOnChange = (e) => {
		e && e.preventDefault();
		setValue(e.target.value);
		setError && setError("");
		setDirtyBit && setDirtyBit(true);
	};

	const [passwordVisible, setPasswordVisible] = useState(false);
	const passwordVisibilityClicked = (e) => {
		e && e.preventDefault();
		setPasswordVisible(!passwordVisible);
	};

	const InputLabelProps = labelAlwaysAtTop ? { shrink: true } : {};

	return (
		<TextField
			data-private
			style={theStyle}
			id={inputID}
			label={inputLabel}
			value={value}
			aria-label={`Enter ${inputLabel}`}
			onChange={handleOnChange}
			onBlur={handleOnBlur}
			type={passwordVisible ? "text" : "password"}
			error={errorText !== undefined && errorText.length > 0}
			placeholder={placeholderText}
			helperText={subText}
			variant="outlined"
			margin="normal"
			fullWidth
			disabled={isDisabled}
			required={isOptional ? false : true}
			autoFocus={isAutoFocus ? true : false}
			InputLabelProps={InputLabelProps}
			inputProps={{ maxLength: theMaxLength, ...autoCompleteProps }}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Tooltip title={"Toggle Password Visibility."} disableFocusListener>
							<IconButton
								aria-label={"Toggle Password Visibility."}
								onClick={passwordVisibilityClicked}
								onMouseDown={handleMouseDownPassword}
								edge="end"
								size="large"
								style={{ marginRight: "0.25rem" }}
							>
								{passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
							</IconButton>
						</Tooltip>
					</InputAdornment>
				),
			}}
		/>
	);
};

export default CustomFormPassword;
