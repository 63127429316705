import EnvConfig from "./EnvConfig";
import Constants from "./Constants";
import { isDarkTheme } from "./Utils";

export const isBoardLocal = () => {
	return EnvConfig.boardEnvironmentType === Constants.localString;
};

//the below functions are checking which api backend we are pointing to

export const isLocalApi = () => {
	return EnvConfig.apiEnvironmentType === Constants.localString;
};

export const isDevelopment = () => {
	return EnvConfig.apiEnvironmentType === Constants.developmentString;
};

export const isProduction = () => {
	return EnvConfig.apiEnvironmentType === Constants.productionString;
};

export const isBlackboardDev = () => {
	return EnvConfig.customer === "Transact" && isDevelopment();
};

export const isBlackboardProd = () => {
	return EnvConfig.customer === "Transact" && isProduction();
};

export const isBlackboard = () => {
	return isBlackboardDev() || isBlackboardProd();
};

export const isAramarkDev = () => {
	return EnvConfig.customer === "Aramark" && isDevelopment();
};

export const isAramarkProd = () => {
	return EnvConfig.customer === "Aramark" && isProduction();
};

export const isAramark = () => {
	return isAramarkDev() || isAramarkProd();
};

export const isTouchNetDev = () => {
	return EnvConfig.customer === "TouchNet" && isDevelopment();
};

export const isTouchNetProd = () => {
	return EnvConfig.customer === "TouchNet" && isProduction();
};

export const isTouchNet = () => {
	return isTouchNetDev() || isTouchNetProd();
};

export const isHangryDev = () => {
	return EnvConfig.customer === "Hangry" && (isDevelopment() || isLocalApi());
};

export const isHangryCanadaProd = () => {
	return EnvConfig.customer === "Hangry Canada" && isProduction();
};

export const isHangryUSProd = () => {
	return EnvConfig.customer === "Hangry USA" && isProduction();
};

export const isHangry = () => {
	return isHangryDev() || isHangryCanadaProd() || isHangryUSProd();
};

export const getLogoImgBasedOnEnv = (themeType) => {
	const logoColor = isDarkTheme(themeType) ? "_white" : "_black";
	let brand = "dev";

	if (isBlackboard()) brand = "blackboard";
	else if (isAramark()) brand = "aramark";
	else if (isTouchNet()) brand = "touchnet";
	else if (isHangryCanadaProd() || isHangryUSProd()) brand = "hangry";
	else if (isHangryDev()) brand = "dev"; //generic word 'mobile order' for us to use in videos for partners

	return "/images/logos/" + brand + "_logo" + logoColor + ".png";
};

export const getVersionInfo = () => {
	return EnvConfig.customer + " - " + EnvConfig.apiEnvironmentType + " - v." + EnvConfig.version?.replace("_", " at ");
};

export const getImageFromURL = (filename, placeholderURL) => {
	if (!filename || filename.length === 0) return placeholderURL ? placeholderURL : "";
	return EnvConfig.baseImageURL + filename;
};
