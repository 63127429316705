import { combineReducers } from "redux";
import * as Actions from "./Actions";
// import { Log, LogDebug } from "../utils/Utils";
import * as Process from "./ProcessServerData";

function boardReducer(state = {}, { type, payload }) {
	if (type === Actions.UPDATE_BOARD) {
		Process.processBoard(payload?.board);
	}
	switch (type) {
		case Actions.LOGOUT_BOARD:
			return {};
		case Actions.REMOVE_AUTH_TOKEN:
			return {};
		case Actions.UPDATE_BOARD:
			return { ...state, board: payload.board };
		// boardUpdatedAtDateTime: Date.now()
		default:
			return state;
	}
}

function authReducer(state = { token: undefined, is_public: undefined }, { type, payload }) {
	switch (type) {
		case Actions.UPDATE_AUTH_TOKEN:
			return { ...state, token: payload.token };
		case Actions.REMOVE_AUTH_TOKEN:
			return { ...state, token: undefined };
		case Actions.CLEAR_AUTH_DATA:
			return {};
		case Actions.UPDATE_AUTH_ACCESSTYPE: {
			const tokenCheck = payload?.access?.is_public === true ? { token: undefined } : {};
			//handles case where switching from private to public (clear the token)
			return {
				...state,
				...payload.access,
				...tokenCheck,
				// is_public: payload.access?.is_public,
				// theme_type: payload.access?.theme_type,
				// board_name: payload.access?.board_name,
				// campus_name: payload.access?.campus_name,
				// boardid: payload.access?.boardid,
				// campusid: payload.access?.campusid,
			};
		}
		default:
			return state;
	}
}

function settingsReducer(state = { themeType: "light" }, { type, payload }) {
	switch (type) {
		case Actions.UPDATE_THEME_TYPE:
			return { ...state, themeType: payload.themeType };
		default:
			return state;
	}
}

function snackbarReducer(state = { notifications: [], history: [] }, action) {
	switch (action.type) {
		case Actions.LOGOUT_BOARD:
		case Actions.SNACKBAR_CLEAR_HISTORY:
			return { ...state, history: [] };
		case Actions.SNACKBAR_ENQUEUE:
			return {
				...state,
				notifications: [
					...state.notifications,
					{
						key: action.key,
						...action.notification,
					},
				],
				history: action.notification.excludeFromHistory ? [...state.history] : [...state.history, { ...action.notification }],
			};
		case Actions.SNACKBAR_CLOSE:
			return {
				...state,
				notifications: state.notifications.map((notification) =>
					action.dismissAll || notification.key === action.key ? { ...notification, dismissed: true } : { ...notification }
				),
			};
		case Actions.SNACKBAR_REMOVE:
			return {
				...state,
				notifications: state.notifications.filter((notification) => notification.key !== action.key),
			};

		default:
			return state;
	}
}

const AllReducers = combineReducers({
	authData: authReducer,
	boardData: boardReducer,
	settingsData: settingsReducer,
	snackbarData: snackbarReducer,
});

const RootReducer = (state, action) => {
	return AllReducers(state, action);
};

export default RootReducer;
