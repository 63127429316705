import { Log } from "../utils/Utils";
import { getNowDate } from "../utils/DateUtils";
import * as ApiCall from "./../api/ApiCall";

export const API = "api:call";

export const SNACKBAR_CLEAR_HISTORY = "snackbar:clearHistory";
export const SNACKBAR_ENQUEUE = "snackbar:enqueue";
export const SNACKBAR_CLOSE = "snackbar:close";
export const SNACKBAR_REMOVE = "snackbar:remove";
export const UPDATE_THEME_TYPE = "settings:updateThemeType";

export const UPDATE_AUTH_ACCESSTYPE = "auth:accesstype";
export const UPDATE_AUTH_TOKEN = "auth:updateToken";
export const REMOVE_AUTH_TOKEN = "auth:removeToken";
export const CLEAR_AUTH_DATA = "auth:clearAllData";
export const UPDATE_BOARD = "board:update";
export const LOGOUT_BOARD = "board:logout";

export const enqueueSnackbar = (notification) => {
	const key = notification.options && notification.options.key;
	Log((notification?.message || "") + " || " + (notification?.title || ""));
	return {
		type: SNACKBAR_ENQUEUE,
		notification: {
			...notification,
			key: key || new Date().getTime() + Math.random(),
			dateTimestamp: getNowDate(),
		},
	};
};

export const closeSnackbar = (key) => ({
	type: SNACKBAR_CLOSE,
	dismissAll: !key, // dismiss all if no key has been defined
	key,
});

export const removeSnackbar = (key) => ({
	type: SNACKBAR_REMOVE,
	key,
});

export const clearHistorySnackbar = () => ({
	type: SNACKBAR_CLEAR_HISTORY,
});

///////BOARD/////////
export const updateBoard = (newBoard) => ({
	type: UPDATE_BOARD,
	payload: {
		board: newBoard,
	},
});

export const logoutBoard = () => ({
	type: LOGOUT_BOARD,
	payload: {
		board: undefined,
	},
});

///////AUTH/////////
export const clearToken = () => ({
	type: REMOVE_AUTH_TOKEN,
	payload: {
		token: undefined,
	},
});

export const clearAllAuthData = () => ({
	type: CLEAR_AUTH_DATA,
	payload: {},
});

///////SETTINGS/////////

export const updateThemeType = (newType) => ({
	type: UPDATE_THEME_TYPE,
	payload: {
		themeType: newType,
	},
});

///////API MIDDLEWARE HELPERS/////////

export const updateIndividualData = (obj, objKey, actionType) => {
	return {
		type: actionType,
		payload: {
			[objKey]: obj,
		},
	};
};

export const updateArrayData = (array, arrayKey, actionType) => {
	return {
		type: actionType,
		payload: {
			[arrayKey]: array,
		},
	};
};

export const defaultMapStateToProps = (state) => ({
	authData: state.authData,
	boardData: state.boardData,
	settingsData: state.settingsData,
	snackbarData: state.snackbarData,
});

export const defaultActionsToProps = {
	doApiCall: ApiCall.apiCall,
	doClearAllAuthData: clearAllAuthData,
	doClearHistorySnackbar: clearHistorySnackbar,
	doUpdateThemeType: updateThemeType,
};

export const settingsMapStateToProps = (state) => ({
	settingsData: state.settingsData,
});
