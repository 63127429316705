import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";
import { LogDebug } from "../utils/Utils";
import ScreenContainer from "../containers/ScreenContainer";

const getNextIndex = (board, visibleIndex) => {
	if (!board || board.screens.length === 0 || visibleIndex === -1) return -1;
	//at the end of array so return the first index
	if (board.screens.length - 1 === visibleIndex) return 0;
	else return visibleIndex + 1;
};

const areEqual = (prevProps, nextProps) => {
	let result = false;

	if (!prevProps?.boardData?.board) result = false;
	if (prevProps?.boardData?.board?.last_updated === nextProps?.boardData?.board?.last_updated) result = true;
	
	if (result) LogDebug("BoardContainer did not render areEqual check=" + result);
	else LogDebug("BoardContainer rendered = areEqual check=" + result);

	return result;
};

const BoardContainer = ({ classes, boardData }) => {
	const { board } = boardData;

	const [visibleIndex, setVisibleIndex] = useState(() => {
		if (!board || board.screens.length === 0) return -1;
		else return 0;
	});

	const doGoToNextScreen = () => {
		LogDebug("doGoToNextScreen called");
		if (!board || board.screens.length === 0 || board.screens.length === 1) return;

		const newIndex = getNextIndex(board, visibleIndex);
		setVisibleIndex(newIndex);
	};

	if (!board) return <></>;
	if (board.screens.length === 0) return <div>NO SCREENS AVAILABLE</div>;

	const numberOfScreens = board.screens.length;
	LogDebug("BoardContainer view rendered");

	return (
		<div className={classes.boardContainer}>
			{board.screens.map((s, idx) => {
				return (
					<ScreenContainer
						key={idx}
						classes={classes}
						screen={s}
						numberOfScreens={numberOfScreens}
						outterPadding={board?.outter_padding || 0}
						isVisible={visibleIndex === s.index}
						doGoToNextScreen={doGoToNextScreen}
					/>
				);
			})}
		</div>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(React.memo(BoardContainer, areEqual));
//this container will iterate/map the screens to screen-containers
