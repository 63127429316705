import { LogDebug } from "./Utils";

export const ScreenLayoutTypes = {
	kSingleScreenView: 1,
	kSplitScreenPortrait: 20,
	kSplitScreenLandscape: 21,

	kTriScreenPortrait: 30,
	kTriScreenLandscape: 31,
	kTriScreenBottomOneTwoPortrait: 33,
	kTriScreenTopOneTwoPortrait: 34,
	kTriScreenLeftSideOneTwoLandscape: 35,
	kTriScreenRightSideOneTwoLandscape: 36,

	kQuadScreenEqualSquares: 40,
	kQuadScreenEqualMiddleTopBottomHorizontal: 41,
	kQuadScreenEqualMiddleLeftRightVertical: 42,
	kQuadScreenThreeMiddleOneBottom: 43,
	kQuadScreenThreeMiddleOneTop: 44,
};

export const ScreenLayoutOptions = [
	{ value: ScreenLayoutTypes.kSingleScreenView, label: "Single View (Default)", description: "One full screen view." },
	{
		value: ScreenLayoutTypes.kSplitScreenPortrait,
		label: "Split View (Left-Right)",
		description: "Two views side-by-side - one left and one right.",
	},
	{
		value: ScreenLayoutTypes.kSplitScreenLandscape,
		label: "Split View (Top-Bottom)",
		description: "Two views stacked - one top and one bottom.",
	},
	{
		value: ScreenLayoutTypes.kTriScreenPortrait,
		label: "Triple View (Portrait)",
		description: "Three views side by side-by-side.",
	},
	{
		value: ScreenLayoutTypes.kTriScreenLandscape,
		label: "Triple View (Landscape)",
		description: "Three views stacked on top of each other.",
	},
	{
		value: ScreenLayoutTypes.kTriScreenBottomOneTwoPortrait,
		label: "Triple View (Bottom Footer, Two Side-by-Side)",
		description: "Bottom footer view with two views below side-by-side.",
	},
	{
		value: ScreenLayoutTypes.kTriScreenTopOneTwoPortrait,
		label: "Triple View (Top Header, Two Side-by-Side)",
		description: "Top header view with two views below side-by-side.",
	},
	{
		value: ScreenLayoutTypes.kTriScreenLeftSideOneTwoLandscape,
		label: "Triple View (One Left Side, Two Stacked)",
		description: "One left side view with two main views stacked.",
	},
	{
		value: ScreenLayoutTypes.kTriScreenRightSideOneTwoLandscape,
		label: "Triple View (One Right Side, Two Stacked)",
		description: "One right side view with two main views stacked.",
	},
	{
		value: ScreenLayoutTypes.kQuadScreenEqualSquares,
		label: "Quad View (Standard Four Equal Views)",
		description: "Four views in a standard cross pattern.",
	},
	{
		value: ScreenLayoutTypes.kQuadScreenEqualMiddleTopBottomHorizontal,
		label: "Quad View (Header, Footer, Two Middle Side-by-Side)",
		description: "One header view, one footer view and two views in the middle side-by-side.",
	},
	{
		value: ScreenLayoutTypes.kQuadScreenEqualMiddleLeftRightVertical,
		label: "Quad View (Left & Right Sidebars, Two Middle Stacked)",
		description: "Left and right side views and two views in the middle stacked.",
	},
	{
		value: ScreenLayoutTypes.kQuadScreenThreeMiddleOneBottom,
		label: "Quad View (One Footer, Three Side-by-Side)",
		description: "One footer view and three middle views side-by-side.",
	},
	{
		value: ScreenLayoutTypes.kQuadScreenThreeMiddleOneTop,
		label: "Quad View (One Header, Three Side-by-Side)",
		description: "One header view and three middle views side-by-side.",
	},
];

export const ViewTypes = {
	kImageCarousel: 1,
	kVideoCarousel: 2,
	kIframeHtml: 3,
	kIframeUrl: 4,

	kOrderStatusGrid: 10,
	kOrderCompleteList: 11,
	kLocationWaitTimes: 12,
	kLocationCheckinQRCode: 13,
	kLocationMenu: 20,
};

const full100Size = 100;
const perc = "%";
const full100SizePerc = "100%";

export const getViewHeight = (screen, viewPosition) => {
	if (!screen) return full100SizePerc;

	const t = screen.layout_type;

	if (t === ScreenLayoutTypes.kSingleScreenView || t === ScreenLayoutTypes.kSplitScreenPortrait || t === ScreenLayoutTypes.kTriScreenPortrait)
		return full100SizePerc;

	if (
		viewPosition === 1 &&
		(t === ScreenLayoutTypes.kTriScreenLeftSideOneTwoLandscape || t === ScreenLayoutTypes.kTriScreenRightSideOneTwoLandscape)
	)
		return full100SizePerc;

	if ((viewPosition === 1 || viewPosition === 2) && t === ScreenLayoutTypes.kQuadScreenEqualMiddleLeftRightVertical) return full100SizePerc;

	let heightFromPos = 0;
	if (viewPosition === 1) heightFromPos = screen.view_height_1;
	else if (viewPosition === 2) heightFromPos = screen.view_height_2;
	else if (viewPosition === 3) heightFromPos = screen.view_height_3;
	else if (viewPosition === 4) heightFromPos = screen.view_height_4;
	else if (viewPosition === 5) heightFromPos = screen.view_height_5;
	else if (viewPosition === 6) heightFromPos = screen.view_height_6;

	///handle auto heights
	if (heightFromPos === 0) return full100SizePerc;
	else if (heightFromPos === 33) return (1 / 3) * 100 + perc;
	else if (heightFromPos === 66) return (2 / 3) * 100 + perc;
	else return heightFromPos + perc;
};

export const getViewWidth = (screen, viewPosition) => {
	if (!screen) return full100Size;

	const t = screen.layout_type;

	if (t === ScreenLayoutTypes.kSingleScreenView || t === ScreenLayoutTypes.kSplitScreenLandscape || t === ScreenLayoutTypes.kTriScreenLandscape)
		return full100Size;

	if (
		viewPosition === 1 &&
		(t === ScreenLayoutTypes.kTriScreenTopOneTwoPortrait ||
			t === ScreenLayoutTypes.kTriScreenBottomOneTwoPortrait ||
			t === ScreenLayoutTypes.kQuadScreenThreeMiddleOneBottom)
	)
		return full100Size;

	if ((viewPosition === 1 || viewPosition === 2) && t === ScreenLayoutTypes.kQuadScreenEqualMiddleTopBottomHorizontal) return full100Size;

	let widthFromPos = 0;
	if (viewPosition === 1) widthFromPos = screen.view_width_1;
	else if (viewPosition === 2) widthFromPos = screen.view_width_2;
	else if (viewPosition === 3) widthFromPos = screen.view_width_3;
	else if (viewPosition === 4) widthFromPos = screen.view_width_4;
	else if (viewPosition === 5) widthFromPos = screen.view_width_5;
	else if (viewPosition === 6) widthFromPos = screen.view_width_6;

	if (widthFromPos === 0) return undefined;
	else if (widthFromPos === 33) return (1 / 3) * 100;
	else if (widthFromPos === 66) return (2 / 3) * 100;
	else return widthFromPos;
};

export const getOrdersFromBoardData = (boardData, targetView) => {
	if (!boardData || !targetView) return [];
	const { board } = boardData;

	let ordersArray = [];
	board.screens.forEach((s) => {
		s.views.forEach((v) => {
			if (v.viewid === targetView.viewid && v.orders) ordersArray = v.orders;
		});
	});

	return ordersArray;
};

export const getLocationsFromBoardData = (boardData, targetView) => {
	if (!boardData || !targetView) return [];
	const { board } = boardData;

	let locationsArray = [];
	board.screens.forEach((s) => {
		s.views.forEach((v) => {
			if (v.viewid === targetView.viewid && v.locations) locationsArray = v.locations;
		});
	});

	return locationsArray;
};
