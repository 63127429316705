import { Log, isDarkThemeWithObj } from "../utils/Utils";

const PREFIX = "Board";

export const classes = {
	homePageContainer: `${PREFIX}-homePageContainer`,
	invalidPageContainer: `${PREFIX}-invalidPageContainer`,
	authPageContainer: `${PREFIX}-authPageContainer`,
	authForm: `${PREFIX}-authForm`,
	authHeaderText: `${PREFIX}-authHeaderText`,
	authHeaderSubText: `${PREFIX}-authHeaderSubText`,
	authButton: `${PREFIX}-authButton`,
	authBackdrop: `${PREFIX}-authBackdrop`,
	logoImage: `${PREFIX}-logoImage`,
	footerDiv: `${PREFIX}-footerDiv`,
	footerText: `${PREFIX}-footerText`,

	boardContainer: `${PREFIX}-boardContainer`,
	screenContainer: `${PREFIX}-screenContainer`,
	screenLayoutContainer: `${PREFIX}-screenLayoutContainer`,

	viewParentDiv: `${PREFIX}-viewParentDiv`,
	viewHeadingDiv: `${PREFIX}-viewHeadingDiv`,
	viewHeading: `${PREFIX}-viewHeading`,
	viewSubHeading: `${PREFIX}-viewSubHeading`,

	viewInnerDiv: `${PREFIX}-viewInnerDiv`,
	viewInnerDivFlex: `${PREFIX}-viewInnerDivFlex`,
	viewInnerDivBlock: `${PREFIX}-viewInnerDivBlock`,
	viewInnerDivInheritHeight: `${PREFIX}-viewInnerDivInheritHeight`,
	viewInnerDiv100Height: `${PREFIX}-viewInnerDiv100Height`,
	viewInnerDivPadding: `${PREFIX}-viewInnerDivPadding`,
	viewInnerDivPaddingHalf: `${PREFIX}-viewInnerDivPaddingHalf`,
	viewGridBorder: `${PREFIX}-viewGridBorder`,

	orderStatusCellDiv: `${PREFIX}-orderStatusCellDiv`,
	orderNumberDisplay: `${PREFIX}-orderNumberDisplay`,
	orderUserNameDisplay: `${PREFIX}-orderUserNameDisplay`,
	orderStatusDisplay: `${PREFIX}-orderStatusDisplay`,
	orderCompleteCellDiv: `${PREFIX}-orderCompleteCellDiv`,
	orderCompleteRecentlyCellDiv: `${PREFIX}-orderCompleteRecentlyCellDiv`,

	locationListCellGrid: `${PREFIX}-locationListCellGrid`,
	locationListCellDiv: `${PREFIX}-locationListCellDiv`,
	locationIconRoundedSquareImage: `${PREFIX}-locationIconRoundedSquareImage`,
	locationListIconAndNameDiv: `${PREFIX}-locationListIconAndNameDiv`,
	locationListCellName: `${PREFIX}-locationListCellName`,
	locationListCellSubtextDiv: `${PREFIX}-locationListCellSubtextDiv`,
	locationListCellSubtext: `${PREFIX}-locationListCellSubtext`,

	locationQRCodeContainer: `${PREFIX}-locationQRCodeContainer`,
	locationQRCodeName: `${PREFIX}-locationQRCodeName`,

	locationMenuContainer: `${PREFIX}-locationMenuContainer`,
	locationMenuContainerSingle: `${PREFIX}-locationMenuContainerSingle`,
	locationMenuGridContainer: `${PREFIX}-locationMenuGridContainer`,
	locationMenuGridContainerHeading: `${PREFIX}-locationMenuGridContainerHeading`,
	locationMenuGridContainerNoHeading: `${PREFIX}-locationMenuGridContainerNoHeading`,
	locationMenuGridContainerHeadingPortrait: `${PREFIX}-locationMenuGridContainerHeadingPortrait`,
	locationMenuSectionContainer: `${PREFIX}-locationMenuSectionContainer`,
	locationMenuSectionContainerHeading: `${PREFIX}-locationMenuSectionContainerHeading`,
	locationMenuSectionContainerNoHeading: `${PREFIX}-locationMenuSectionContainerNoHeading`,
	locationMenuSectionGroup: `${PREFIX}-locationMenuSectionGroup`,
	locationMenuSectionGroupPortraitNoHeader: `${PREFIX}-locationMenuSectionGroupPortraitNoHeader`,
	locationMenuSectionGroupPortraitHeaderOnly: `${PREFIX}-locationMenuSectionGroupPortraitHeaderOnly`,
	locationMenuSectionGroupPortraitSubheaderOnly: `${PREFIX}-locationMenuSectionGroupPortraitSubheaderOnly`,
	locationMenuSectionGroupPortraitHeaderAndSubHeader: `${PREFIX}-locationMenuSectionGroupPortraitHeaderAndSubHeader`,
	locationMenuSectionGroupLandcape2: `${PREFIX}-locationMenuSectionGroupLandcape2`,
	locationMenuSectionGroupLandcape3: `${PREFIX}-locationMenuSectionGroupLandcape3`,
	locationMenuSectionTitle: `${PREFIX}-locationMenuSectionTitle`,
	locationMenuSectionCoverImg: `${PREFIX}-locationMenuSectionCoverImg`,
	locationMenuImageDiv: `${PREFIX}-locationMenuImageDiv`,
	locationMenuNameDiv: `${PREFIX}-locationMenuNameDiv`,
	locationMenuItemIconRoundedSquareImage: `${PREFIX}-locationMenuItemIconRoundedSquareImage`,

	locationMenuListCellDiv: `${PREFIX}-locationMenuListCellDiv`,
	locationMenuIconRoundedSquareImage: `${PREFIX}-locationMenuIconRoundedSquareImage`,
	locationMenuListIconAndNameDiv: `${PREFIX}-locationMenuListIconAndNameDiv`,
	locationMenuListCellName: `${PREFIX}-locationMenuListCellName`,
	locationMenuListCellSubtextDiv: `${PREFIX}-locationMenuListCellSubtextDiv`,
	locationMenuListCellSubtext: `${PREFIX}-locationMenuListCellSubtext`,

	locationMenuCellDiv: `${PREFIX}-locationMenuCellDiv`,
	locationMenuIconAndNameParentDiv: `${PREFIX}-locationMenuIconAndNameParentDiv`,
	locationMenuIconAndNameDiv: `${PREFIX}-locationMenuIconAndNameDiv`,
	locationMenuCellName: `${PREFIX}-locationMenuCellName`,
	locationMenuCellPrice: `${PREFIX}-locationMenuCellPrice`,
	locationMenuCellDescription: `${PREFIX}-locationMenuCellDescription`,
	locationMenuCellCalories: `${PREFIX}-locationMenuCellCalories`,
	locationMenuCellDivider: `${PREFIX}-locationMenuCellDivider`,

	locationMenuFlexDiv: `${PREFIX}-locationMenuFlexDiv`,
	locationMenuFlexContainer: `${PREFIX}-locationMenuFlexContainer`,
	locationMenuFlexGridContainer: `${PREFIX}-locationMenuFlexGridContainer`,
	locationMenuFlexContainerItem: `${PREFIX}-locationMenuFlexContainerItem`,
	locationMenuFlexSectionImage: `${PREFIX}-locationMenuFlexSectionImage`,
	locationMenuFlexGridItem: `${PREFIX}-locationMenuFlexGridItem`,
	locationMenuFlexGridItemWithIconImage: `${PREFIX}-locationMenuFlexGridItemWithIconImage`,
	locationMenuFlexItemImage: `${PREFIX}-locationMenuFlexItemImage`,
	locationMenuFlexCard: `${PREFIX}-locationMenuFlexCard`,
	locationMenuFlexCardContent: `${PREFIX}-locationMenuFlexCardContent`,
	locationMenuFlexCardNamePriceParentDiv: `${PREFIX}-locationMenuFlexCardNamePriceParentDiv`,
	locationMenuFlexCardNamePrice: `${PREFIX}-locationMenuFlexCardNamePrice`,
	locationMenuFlexCardDescCals: `${PREFIX}-locationMenuFlexCardDescCals`,
	locationMenuFlexCardCellCalories: `${PREFIX}-locationMenuFlexCardCellCalories`,
	locationMenuFlexCardDietaryFlagImg: `${PREFIX}-locationMenuFlexCardDietaryFlagImg`,
	locationMenuFlexCardIconRoundedSquareImage: `${PREFIX}-locationMenuFlexCardIconRoundedSquareImage`,
	locationMenuFlexCardSectionTitle: `${PREFIX}-locationMenuFlexCardSectionTitle`,

	imageCarouselPrent: `${PREFIX}-imageCarouselPrent`,
	imageCarouselImg: `${PREFIX}-imageCarouselImg`,
	reactPlayer: `${PREFIX}-reactPlayer`,
};

export const GlobalStyle = (theme) => {
	const p = theme.palette;
	const isDarkTheme = isDarkThemeWithObj(theme);

	return {
		[`&.${classes.homePageContainer}`]: {
			background: p.background.backgroundWidget,
			color: p.text.primary,
			height: "100vh",
			overflow: "hidden",
		},
		[`&.${classes.invalidPageContainer}`]: {
			background: p.background.backgroundFullPage,
			color: p.text.primary,
			height: "100vh",
			overflow: "hidden",
			margin: "100px auto 0px",
			textAlign: "center",
		},
		[`&.${classes.authPageContainer}`]: {
			background: p.background.backgroundFullPage,
			color: p.text.primary,
			height: "100vh",
			overflow: "hidden",
			margin: "2rem auto 0px",
			textAlign: "center",
		},
		[`& .${classes.authForm}`]: {
			maxWidth: 800,
			margin: "2rem auto 0px",
			border: "1px solid " + p.dividerCell,
			background: p.background.backgroundWidget,
			borderRadius: 10,
			padding: "2rem 2rem",
		},
		[`& .${classes.authHeaderText}`]: {
			fontSize: "1.7rem",
		},
		[`& .${classes.authHeaderSubText}`]: {
			fontSize: "0.8rem",
		},
		[`& .${classes.authButton}`]: {
			height: "3.1rem",
			minHeight: 60,
			maxHeight: 110,
			fontSize: "1.2rem",
		},
		[`& .${classes.authBackdrop}`]: {
			zIndex: 100,
		},
		[`& .${classes.logoImage}`]: { display: "flex", maxWidth: 600, margin: "0px auto", padding: "0px 10px" },
		[`& .${classes.footerDiv}`]: {
			width: "100%",
			textAlign: "center",
			position: "fixed",
			top: "94vh",
			zIndex: 10,
			color: p.text.primary,
		},
		[`& .${classes.footerText}`]: {
			lineHeight: 1.4,
			fontSize: "0.5rem",
		},

		[`& .${classes.boardContainer}`]: { height: "100vh", overflow: "hidden" },
		[`& .${classes.screenContainer}`]: { height: "100vh", overflow: "hidden" },
		[`& .${classes.screenLayoutContainer}`]: { display: "flex", height: "100vh", overflow: "hidden" },

		[`& .${classes.viewParentDiv}`]: { height: "100%", width: "100%", overflow: "hidden", background: p.background.backgroundWidget },
		[`& .${classes.viewHeadingDiv}`]: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignContent: "center",
			alignItems: "center",
			minHeight: "2.5rem",
			textAlign: "center",
			width: "100%",
			padding: "0.2rem 0.1rem",
			borderBottom: "1px solid " + p.dividerView,
		},
		[`& .${classes.viewHeading}`]: { lineHeight: "normal", margin: "0.1rem", color: p.text.primary },
		[`& .${classes.viewSubHeading}`]: { lineHeight: "normal", margin: "0.1rem", color: p.text.primary, fontWeight: 350 },
		[`& .${classes.viewInnerDiv}`]: {
			width: "100%",
			overflow: "hidden",
		},
		[`& .${classes.viewInnerDivFlex}`]: {
			display: "flex",
		},
		[`& .${classes.viewInnerDivBlock}`]: {
			display: "block",
		},
		[`& .${classes.viewInnerDivInheritHeight}`]: {
			height: "inherit",
		},
		[`& .${classes.viewInnerDiv100Height}`]: {
			height: "100%",
		},
		[`& .${classes.viewInnerDivPaddingHalf}`]: {
			padding: "0.5rem",
		},
		[`& .${classes.viewInnerDivPadding}`]: {
			padding: "1.0rem",
		},
		[`& .${classes.viewGridBorder}`]: {
			border: "1px solid " + p.dividerView,
			maxHeight: "100%",
		},

		[`& .${classes.orderStatusCellDiv}`]: {
			border: "0.1rem solid " + p.dividerCell,
			borderRadius: "10px",
			textAlign: "center",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flexDirection: "column",
			minHeight: 100,
			maxWidth: 400,
			margin: "auto",
			padding: "0.5rem 0px",
		},
		[`& .${classes.orderNumberDisplay}`]: {
			lineHeight: "normal",
			fontSize: "1.5rem",
		},
		[`& .${classes.orderUserNameDisplay}`]: {
			lineHeight: "normal",
			fontSize: "0.7rem",
			fontWeight: 300,
		},
		[`& .${classes.orderStatusDisplay}`]: {
			lineHeight: "normal",
			fontSize: "1.0rem",
			marginTop: "0.5rem",
		},

		[`& .${classes.orderCompleteCellDiv}`]: {
			border: "0.1rem solid " + p.dividerCell,
			borderRadius: "10px",
			textAlign: "center",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flexDirection: "column",
			minHeight: 100,
			height: "100%",
			maxWidth: 400,
			margin: "auto",
			padding: "0.5rem 0px",
		},
		[`& .${classes.orderCompleteRecentlyCellDiv}`]: {
			border: "0.1rem solid " + p.secondary.main,
			background: p.secondary.main + "1A",
		},

		[`& .${classes.locationListCellGrid}`]: {
			// minHeight: 100,
			// height: "100%",
			// maxHeight: 200,
		},
		[`& .${classes.locationListCellDiv}`]: {
			border: "0.05rem solid " + p.dividerCell,
			borderRadius: "10px",
			textAlign: "center",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flexDirection: "column",
			minHeight: 100,
			height: "100%",
			// maxHeight: 200,
			maxWidth: 650,
			margin: "auto",
			// padding: "0.7rem 0.2rem 0.7rem 0.7rem",
			padding: "0.5rem",
		},
		[`& .${classes.locationIconRoundedSquareImage}`]: {
			width: "3rem",
			height: "3rem",
			borderRadius: "10px",
			border: "1px solid " + p.dividerView,
			margin: "auto 0",
			objectFit: "cover",
		},
		[`& .${classes.locationListIconAndNameDiv}`]: {
			display: "flex",
			flexDirection: "row",
			width: "100%",
			alignItems: "center",
			overflow: "hidden",
		},
		[`& .${classes.locationListCellName}`]: {
			lineHeight: 1,
			fontSize: "1.3rem",
			textAlign: "left",
			marginLeft: "0.8rem",
		},
		[`& .${classes.locationListCellSubtextDiv}`]: {
			display: "flex",
			flexDirection: "rows",
			textAlign: "left",
			justifyContent: "space-between",
			width: "100%",
			marginTop: "0.5rem",
			overflow: "hidden",
		},
		[`& .${classes.locationListCellSubtext}`]: {
			fontSize: "0.9rem",
			marginTop: "0.1rem",
			lineHeight: "normal",
		},

		[`& .${classes.locationQRCodeContainer}`]: {
			background: "#FFF",
			padding: "10px 10px 0 10px",
			margin: "10px auto",
			textAlign: "center",
			maxWidth: 800,
		},
		[`& .${classes.locationQRCodeName}`]: {
			marginBottom: "1.0rem",
			color: "#000",
			textAlign: "center",
			width: "100%",
			fontSize: "1.3rem",
		},

		[`& .${classes.imageCarouselPrent}`]: {
			width: "100%",
			height: "100%",
		},

		[`& .${classes.imageCarouselImg}`]: {
			maxWidth: "100%",
			maxHeight: "100%",
			width: "100%",
			height: "100%",
			margin: "auto",
			overflow: "hidden",
			objectFit: "contain",
		},

		[`& .${classes.reactPlayer}`]: {
			borderRadius: 10,
			overflow: "hidden",
			margin: "auto",
			background: p.background.backgroundFullPage,
		},

		[`& .${classes.locationMenuContainer}`]: {
			margin: "0.3rem",
			width: "100%",
		},
		[`& .${classes.locationMenuContainerSingle}`]: {
			margin: "0.3rem",
			display: "flex",
			width: "100%",
		},
		[`& .${classes.locationMenuGridContainer}`]: {
			width: "100%",
			height: "100%",
			margin: 0,
		},
		[`& .${classes.locationMenuGridContainerNoHeading}`]: {
			marginTop: "0rem",
		},
		[`& .${classes.locationMenuGridContainerHeading}`]: {
			// marginTop: "-1rem",
		},
		[`& .${classes.locationMenuGridContainerHeadingPortrait}`]: {
			marginTop: "0rem",
		},
		[`& .${classes.locationMenuSectionContainer}`]: {
			border: "1px solid " + p.dividerCell,
			borderRadius: "10px",
			overflow: "hidden",
		},
		[`& .${classes.locationMenuSectionContainerNoHeading}`]: {
			height: "100%",
		},
		[`& .${classes.locationMenuSectionContainerHeading}`]: {
			height: "100%",
		},
		[`& .${classes.locationMenuSectionGroup}`]: {
			minWidth: "500px",
			maxWidth: "700px",
		},
		[`& .${classes.locationMenuSectionGroupPortraitHeaderOnly}`]: {
			padding: "0.25rem 2% 0.75rem !important",
			height: "calc(100% - 2rem)",
			maxHeight: "calc(100% - 2rem)",
		},
		[`& .${classes.locationMenuSectionGroupPortraitSubheaderOnly}`]: {
			padding: "0 2% 0 !important",
			height: "calc(100% - 2rem)",
			maxHeight: "calc(100% - 2rem)",
		},
		[`& .${classes.locationMenuSectionGroupPortraitHeaderAndSubHeader}`]: {
			padding: "0.25rem 2% 0.75rem !important",
			height: "calc(100% - 3.5rem)",
			maxHeight: "calc(100% - 3.5rem)",
		},
		[`& .${classes.locationMenuSectionGroupPortraitNoHeader}`]: {
			paddingTop: "0.1rem !important",
			paddingBottom: "0.1rem !important",
			paddingLeft: "0.5rem !important",
			paddingRight: "0.5rem !important",
			height: "100%",
		},
		[`& .${classes.locationMenuSectionGroupLandcape2}`]: {
			padding: "2rem 2% 0 !important",
			height: "calc(100% + 2em)",
		},
		[`& .${classes.locationMenuSectionGroupLandcape3}`]: {
			padding: "2rem 2% 0 !important",
			height: "calc(100% + 2em)",
		},
		[`& .${classes.locationMenuSectionTitle}`]: {
			textAlign: "center",
			fontSize: "1.2rem",
			padding: "0.35rem 0",
			background: p.background.tableHeaderBackground,
			borderBottom: "1px solid " + p.dividerCell,
		},
		[`& .${classes.locationMenuSectionCoverImg}`]: {
			width: "100%",
			height: "auto",
			lineHeight: 1,
			marginBottom: "-0.4rem",
		},
		[`& .${classes.locationMenuImageDiv}`]: {
			display: "contents",
		},
		[`& .${classes.locationMenuNameDiv}`]: {
			paddingTop: "0.313rem",
			paddingBottom: "1.875rem",
			textAlign: "center",
		},
		[`& .${classes.locationMenuIconAndNameDiv}`]: {
			display: "flex",
			flexDirection: "row",
			width: "100%",
			alignItems: "center",
		},
		[`& .${classes.locationMenuItemIconRoundedSquareImage}`]: {
			width: "3.0rem",
			height: "3.0rem",
			borderRadius: "10px",
			marginRight: "0.3rem",
			objectFit: "cover",
		},

		[`& .${classes.locationMenuCellDiv}`]: {
			display: "flex",
			background: isDarkTheme ? p.background.tableHeaderBackground : "white",
			padding: "0.4rem 0.4rem",
			alignItems: "center",
		},
		[`& .${classes.locationMenuIconAndNameParentDiv}`]: {
			display: "flex",
			flexDirection: "column",
			margin: "0 0.3rem",
			width: "100%",
		},
		[`& .${classes.locationMenuIconAndNameDiv}`]: {
			display: "flex",
			flexDirection: "row",
			width: "100%",
			alignItems: "center",
			justifyContent: "space-between",
		},
		[`& .${classes.locationMenuCellName}`]: {
			fontSize: "0.9rem",
			textAlign: "left",
			// paddingRight: "1rem",
		},
		[`& .${classes.locationMenuCellPrice}`]: {
			fontSize: "0.9rem",
			textAlign: "right",
			fontStyle: "italic",
			fontWeight: 350,
		},
		[`& .${classes.locationMenuCellDescription}`]: {
			fontSize: "0.65rem",
			fontWeight: 350,
			textAlign: "justify",
			textJustify: "inter-word",
			maxHeight: 130,
			overflow: "hidden",
			// width: "80%",
		},
		[`& .${classes.locationMenuCellCalories}`]: {
			fontSize: "0.8rem",
			fontStyle: "italic",
			fontWeight: 350,
			textAlign: "justify",
			textJustify: "inter-word",
			display: "flex",
			alignItems: "center",
		},
		[`& .${classes.locationMenuCellDivider}`]: {
			marginTop: "0rem",
			marginBottom: "0rem",
		},
		[`& .${classes.locationMenuFlexContainer}`]: {
			display: "flex",
			flexFlow: "column wrap",
			gap: "0 20px",
			height: "-webkit-fill-available",
			overflow: "hidden",
		},

		[`& .${classes.locationMenuFlexDiv}`]: {
			width: "100%",
			height: "100%",
		},
		[`& .${classes.locationMenuFlexGridContainer}`]: {
			display: "flex",
			margin: 0,
			width: "100%",
		},
		[`& .${classes.locationMenuFlexContainerItem}`]: {
			width: "auto",
		},
		[`& .${classes.locationMenuFlexSectionImage}`]: {
			width: "7rem",
			height: "4rem",
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
		},
		[`& .${classes.locationMenuFlexGridItem}`]: {
			display: "flex",
			margin: "0.5rem 0.5rem 0 0.5rem",
			minWidth: 400,
			width: "100%",
			maxWidth: 450,
			height: 200,
		},
		[`& .${classes.locationMenuFlexGridItemWithIconImage}`]: {
			maxWidth: 600,
			minWidth: 600,
		},
		[`& .${classes.locationMenuFlexItemImage}`]: {
			width: "5rem",
			height: "4rem",
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
			padding: "0.25rem 0.5rem 0 0",
		},

		[`& .${classes.locationMenuFlexCard}`]: {
			background: isDarkTheme ? p.background.tableHeaderBackground : "white",
			border: "1px solid " + p.dividerCell,
			margin: "2px",
			minWidth: "100%",
			maxWidth: "100%",
			height: "-webkit-fill-available",
			boxShadow: "none",
		},
		[`& .${classes.locationMenuFlexCardContent}`]: {
			width: "100%",
			height: "100%",
			position: "relative",
			padding: "0px !important",
		},
		[`& .${classes.locationMenuFlexCardNamePriceParentDiv}`]: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			width: "100%",
		},
		[`& .${classes.locationMenuFlexCardNamePrice}`]: {
			padding: "0.1rem 0.4rem 0 0.3rem",
			display: "flex",
			justifyContent: "space-between",
		},
		[`& .${classes.locationMenuFlexCardDescCals}`]: {
			padding: "0 0.4rem 0.2rem 0.3rem",
		},
		[`& .${classes.locationMenuFlexCardDietaryFlagImg}`]: {
			width: "1.0rem",
			height: "1.0rem",
			marginRight: 15,
		},
		[`& .${classes.locationMenuFlexCardCellCalories}`]: {
			fontSize: "0.8rem",
			fontStyle: "italic",
			fontWeight: 350,
			textAlign: "justify",
			textJustify: "inter-word",
			display: "flex",
			alignItems: "center",
		},
		[`& .${classes.locationMenuFlexCardCellDescription}`]: {
			fontSize: "0.5rem",
			fontWeight: 350,
			textAlign: "justify",
			textJustify: "inter-word",
			width: "80%",
		},
		[`& .${classes.locationMenuFlexCardIconRoundedSquareImage}`]: {
			width: "3.0rem",
			height: "3.0rem",
			borderRadius: "10px",
			marginRight: "0.2rem",
			marginLeft: "0.3rem",
			objectFit: "cover",

			// marginTop: "0.3rem",
		},
		[`& .${classes.locationMenuFlexCardSectionTitle}`]: {
			textAlign: "center",
			fontSize: "1.2rem",
			padding: "0.35rem 0",
			background: p.background.tableHeaderBackground,
			border: "1px solid " + p.dividerCell,
			borderRadius: 6,
			margin: "0 auto",
		},
	};
};
