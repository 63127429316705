import React from "react";
import { useTheme } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Loading = () => {
	const theme = useTheme();

	if (!theme.isThemeLoaded) return <></>;

	return (
		<Backdrop style={{ zIndex: 100 }} open={true} onClick={() => {}}>
			<CircularProgress id="loading" alt="loading" aria-label="Loading. Please wait..." color="secondary" size={100} />
		</Backdrop>
	);
};

export default Loading;
