import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ReportWebVitals from "./utils/ReportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Store, Persistor } from "./store/Store";
import { PersistGate } from "redux-persist/integration/react";
import Loading from "./utils/utilcomponents/Loading";

ReactDOM.render(
	<Provider store={Store}>
		<PersistGate loading={<Loading />} persistor={Persistor}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);

ReportWebVitals();
