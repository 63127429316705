const CustomFormStyles = (theme) => {
	const p = theme.palette;

	return {
		textfieldInput: { width: "100%", marginBottom: 0, marginTop: 0 },
		textfieldInputSave: { width: "50%", marginBottom: 0, marginTop: 0 },

		alertError: {
			marginTop: 0,
			marginBottom: 0,
			color: "#FFF",
			background: p.error.main,
			"& .MuiAlert-icon": {
				fontSize: "1.2rem",
				alignItems: "center",
			},
			"& .MuiAlert-message": {
				fontWeight: 400,
			},
		},
		alertActionButton: {
			color: "#FFF !important",
			paddingTop: 2,
			"& .MuiSvgIcon-root": { color: "#FFF" },
			"& .MuiButtonBase-root.MuiIconButton-root": {
				"&:hover": {
					background: "#00000057",
					border: "1px solid #FFF",
					color: "#FFF !important",
				},
				"&:focus": {
					background: "#00000057",
					border: "2px solid #FFF",
					color: "#FFF !important",
				},
			},
		},
	};
};

export default CustomFormStyles;
