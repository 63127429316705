import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { settingsMapStateToProps } from "./store/Actions";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import Constants from "./utils/Constants";
import { LogDebug } from "./utils/Utils";
import EnvConfig from "./utils/EnvConfig";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { CustomTheme } from "./theme/CustomTheme";
import CssBaseline from "@mui/material/CssBaseline";
import "./theme/index.css";

import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import ErrorBoundary from "./utils/utilcomponents/ErrorBoundary";
import PlaceholderWhileLoadingLazy from "./utils/utilcomponents/PlaceholderWhileLoadingLazy";
import HomePage from "./pages/HomePage";
import InvalidBoardPage from "./pages/InvalidBoardPage";
import AuthenticationPage from "./pages/AuthenticationPage";

dayjs.extend(updateLocale);
LicenseInfo.setLicenseKey(EnvConfig.muiProLicenseKey);

const App = ({ settingsData }) => {
	////THEME////
	const themeType = settingsData.themeType;
	const [theCurrentTheme, setTheCurrentTheme] = useState(() => CustomTheme(themeType));

	useEffect(() => {
		LogDebug("themeType=" + themeType);
		LogDebug("theCurrentTheme.palette.mode=" + theCurrentTheme?.palette?.mode);
		if (theCurrentTheme && theCurrentTheme.palette.mode === themeType) return;
		//same type already being used. dont want this called initially if we set the theme in the default
		LogDebug("setTheCurrentTheme called");
		const newTheme = CustomTheme(themeType);
		//LogDebug(newTheme); //so we can view this object props in the console while developing
		setTheCurrentTheme(newTheme);
	}, [themeType]); //when the themetype changes, we rebuild the theme muitheme()

	LogDebug("app.js rendered");

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theCurrentTheme}>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<CssBaseline />
					<ErrorBoundary>
						<React.Suspense fallback={<PlaceholderWhileLoadingLazy />}>
							<Switch>
								<Route exact path={`/${Constants.invalidPageUrl}`}>
									<InvalidBoardPage />
								</Route>
								<Route path={`/:inputcampusid?/:inputboardid?/${Constants.authPageUrl}`}>
									<AuthenticationPage />
								</Route>
								<Route path={`/:inputcampusid?/:inputboardid?`}>
									<HomePage />
								</Route>
							</Switch>
						</React.Suspense>
					</ErrorBoundary>
				</LocalizationProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default connect(settingsMapStateToProps, {})(App);
//only passing the settings props here fixed this parent component being unnecessarily re-rendered
