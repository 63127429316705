import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { LogDebug } from "../utils/Utils";
import { CircularProgress, Typography } from "@mui/material";
import ReactPlayer from "react-player";

const VideoPlayer = ({ classes, url, doGoToNextVideo }) => {
	const onReadyCallback = () => {
		//setBusy(false);
	};

	const onEndedCallback = () => {
		LogDebug("onEndedCallback");
		doGoToNextVideo();
	};

	const onErrorCallback = (err) => {
		LogDebug("onErrorCallback - " + err);
		doGoToNextVideo();
	};

	if (!url || url.length === 0) return <></>;

	LogDebug("videoview rendered");

	return (
		<ReactPlayer
			url={url}
			width={"100%"}
			height={"100%"}
			playing
			controls={false}
			muted
			config={{
				youtube: {
					playerVars: { showinfo: 0, modestbranding: 1, controls: 0, fs: 0, color: "white" },
				},
				vimeo: {
					playerOptions: { controls: false, title: false },
				},
			}}
			className={classes.reactPlayer}
			onReady={onReadyCallback}
			onEnded={onEndedCallback}
			onError={onErrorCallback}
		/>
	);
};

const areEqual = (prevProps, nextProps) => {
	LogDebug(prevProps.url);
	LogDebug(nextProps.url);
	if (prevProps.url === nextProps.url) return true;
	return false;
	/*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
};

export default React.memo(VideoPlayer, areEqual);
//only re-render if the url changes