import { doesContainLowerCase, doesContainNumber, doesContainUpperCase, isValidEmail } from "./Utils";

//returns false if invalid and will setErrorCallbackWithErrorMessage
const ValidateInput = (
	inputString,
	setErrorCallback,
	{
		inputName,
		isEmail,
		minLength,
		maxLength,
		containsUpperCase,
		containsLowerCase,
		containsNumber,
		containsSpecial,
		isHex,
		includesHttps,
		customFunction,
		customFunctionError,
		lessThan,
		greaterThan,
		canBeZero
	}
) => {
	const extraInfo = inputName ? " (" + inputName + ")" : "";

	if (inputString === null || inputString === undefined || inputString.length === 0) {
		setErrorCallback && setErrorCallback("Input Required" + extraInfo);
		return false;
	}

	if (isEmail) {
		const isValid = isValidEmail(inputString);
		if (!isValid) {
			setErrorCallback && setErrorCallback("Invalid Email Format" + extraInfo);
			return false;
		}
	}

	if (minLength && minLength > 0 && inputString.length < minLength) {
		setErrorCallback && setErrorCallback("Minimum " + minLength + " Characters Required" + extraInfo);
		return false;
	}

	if (maxLength && maxLength > 0 && inputString.length > maxLength) {
		setErrorCallback && setErrorCallback("Maximum " + maxLength + " Characters" + extraInfo);
		return false;
	}

	if (lessThan && !(Number(inputString) <= Number(lessThan))) {
		if (canBeZero && Number(inputString) === 0) return true;
		setErrorCallback && setErrorCallback(`Cannot be greater than ${lessThan}` + extraInfo);
		return false;
	}

	if (greaterThan && !(Number(inputString) >= Number(greaterThan))) {
		if (canBeZero && Number(inputString) === 0) return true;
		setErrorCallback && setErrorCallback(`Must be greater than ${greaterThan}` + extraInfo);
		return false;
	}

	if (containsLowerCase && !doesContainLowerCase(inputString)) {
		setErrorCallback && setErrorCallback("Must contain a lowercase letter." + extraInfo);
		return false;
	}

	if (containsUpperCase && !doesContainUpperCase(inputString)) {
		setErrorCallback && setErrorCallback("Must contain an uppercase letter." + extraInfo);
		return false;
	}

	if (includesHttps && !inputString.toUpperCase().includes("HTTPS://")) {
		setErrorCallback && setErrorCallback("Must include https://" + extraInfo);
		return false;
	}

	if (containsNumber && !doesContainNumber(inputString)) {
		setErrorCallback && setErrorCallback("Must contain a number." + extraInfo);
		return false;
	}

	if (customFunction && !customFunction(inputString)) {
		setErrorCallback && setErrorCallback(customFunctionError);
		return false;
	}

	return true;
};



export default ValidateInput;
