import { isDarkTheme } from "../utils/Utils";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const important = " !important";
const white = "#FFF";
const black = "#000";

export const primaryColor = (themeType) => (isDarkTheme(themeType) ? "#0365AE" : "#00568B");
export const secondaryColor = (themeType) => (isDarkTheme(themeType) ? "#008162" : "#006549");
export const successColor = (themeType) => (isDarkTheme(themeType) ? "#008162" : "#006549");
export const dangerColor = (themeType) => (isDarkTheme(themeType) ? "#C80000" : "#a00000");
export const warningColor = (themeType) => (isDarkTheme(themeType) ? "#FE9A76" : "#FE9A76");

export const primaryTextColor = (themeType) => (isDarkTheme(themeType) ? "#FFF" : "#121212");
export const secondaryTextColor = (themeType) => (isDarkTheme(themeType) ? "#CCC" : "#444");
export const tertiaryTextColor = (themeType) => (isDarkTheme(themeType) ? "#999" : "#888");
export const quaternaryTextColor = (themeType) => (isDarkTheme(themeType) ? "#606060" : "#c7c7c7");

export const dividerColor = (themeType) => (isDarkTheme(themeType) ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.12)");
export const dividerColorView = (themeType) => (isDarkTheme(themeType) ? "rgba(255, 255, 255, 0.25)" : "rgba(0, 0, 0, 0.25)");
export const dividerColorCell = (themeType) => (isDarkTheme(themeType) ? "rgba(255, 255, 255, 0.4)" : "rgba(0, 0, 0, 0.4)");

export const backgroundFullPage = (themeType) => (isDarkTheme(themeType) ? "#000" : "#FCFCFC");
export const widgetBorder = (themeType) => (isDarkTheme(themeType) ? "#0d0d0d" : "#F5F5F5");
export const backgroundWidget = (themeType) => (isDarkTheme(themeType) ? "#090909" : white);

export const inputBoxBackground = (themeType) => (isDarkTheme(themeType) ? "#020202" : "#FAFAFA");
export const inputBoxBorder = (themeType) => (isDarkTheme(themeType) ? "#474747" : "#c0c0c0");

export const menuPopUpBackground = (themeType) => (isDarkTheme(themeType) ? "#181818" : "#F6F6F6");
export const menuBorder = (themeType) => "1px solid " + (isDarkTheme(themeType) ? "#444" : "#BBB");

const defaultBackgroundHover = (themeType) => (isDarkTheme(themeType) ? "#282828" : "#E6E6E6");

export const normalButtonBackgroundHover = (themeType) => (isDarkTheme(themeType) ? "#00243e" : "#00568B");
export const normalButtonTextHover = (themeType) => (isDarkTheme(themeType) ? white : white);
export const iconButtonBackgroundHover = (themeType) => defaultBackgroundHover(themeType);

export const selectBackgroundSelected = (themeType) => (isDarkTheme(themeType) ? "#262626" : "#E6E6E6");
export const selectBackgroundSelectedPlusHover = (themeType) => (isDarkTheme(themeType) ? "#333333" : "#E0E0E0");
export const selectBackgroundHover = (themeType) => defaultBackgroundHover(themeType);

export const tabBackgroundSelected = (themeType) => (isDarkTheme(themeType) ? "#202020" : "transparent");
export const tabSelectedText = (themeType) => (isDarkTheme(themeType) ? white : primaryColor(themeType));
export const tabBackgroundHover = (themeType) => defaultBackgroundHover(themeType);
export const tabHoverText = (themeType) => primaryTextColor(themeType);

export const dataGridRowBackgroundSelected = (themeType) => (isDarkTheme(themeType) ? "#252525" : "#E8E8E8");
export const dataGridRowBackgroundSelectedPlusHover = (themeType) => (isDarkTheme(themeType) ? "#303030" : "#E2E2E2");
export const dataGridRowSelectedTextColor = (themeType) => primaryTextColor(themeType);

export const tableCellFocusColor = (themeType) => (isDarkTheme(themeType) ? "rgba(255, 255, 255, 0.4)" : "rgba(0, 0, 0, 0.4)");
export const tableHeaderBackground = (themeType) => (isDarkTheme(themeType) ? "#0e0e0e" : "#e8e8e8");

export const defaultFocusBoxCss = (themeType) => {
	return {
		"&:focus": {
			outline: "none",
			border: "1px solid " + primaryTextColor(themeType),
			boxShadow: "inset 0 0 0 1px " + primaryTextColor(themeType),
		},
	};
};

export const CustomTheme = (themeType) => {
	let theCurrentTheme = createTheme({
		palette: {
			mode: themeType,
			primary: {
				main: primaryColor(themeType),
			},
			secondary: {
				main: secondaryColor(themeType),
			},
			success: {
				main: successColor(themeType),
			},
			error: {
				main: dangerColor(themeType),
			},
			warning: {
				main: warningColor(themeType),
			},
			divider: dividerColor(themeType),
			dividerView: dividerColorView(themeType),
			dividerCell: dividerColorCell(themeType),
			text: {
				primary: primaryTextColor(themeType),
				secondary: secondaryTextColor(themeType),
				tertiary: tertiaryTextColor(themeType),
				quaternary: quaternaryTextColor(themeType),
			},
			background: {
				default: backgroundFullPage(themeType),
				backgroundFullPage: backgroundFullPage(themeType),
				backgroundWidget: backgroundWidget(themeType),
				backgroundPopUpMenu: menuPopUpBackground(themeType),
				selectBackgroundHover: selectBackgroundHover(themeType),
				selectBackgroundSelected: selectBackgroundSelected(themeType),
				selectBackgroundSelectedPlusHover: selectBackgroundSelectedPlusHover(themeType),
				backgroundInput: inputBoxBackground(themeType),
				tableHeaderBackground: tableHeaderBackground(themeType),
				buttonBackgroundHover: normalButtonBackgroundHover(themeType),
				iconButtonBackgroundHover: iconButtonBackgroundHover(themeType),
			},
			border: {
				widgetBorder: widgetBorder(themeType),
				inputBoxBorder: inputBoxBorder(themeType),
			},
		},
		isThemeLoaded: true,
		border: {
			menuBorder: menuBorder(themeType),
		},
		shadow: {
			widgetShadow: "inset 0px 0px 2px " + (isDarkTheme(themeType) ? "#444" : "#AAA"),
		},
		typography: {
			h1: { fontWeight: 400, fontSize: "2.5rem", color: primaryTextColor(themeType) },
			h2: { fontWeight: 400, fontSize: "2.1rem", color: primaryTextColor(themeType) },
			h3: { fontWeight: 400, fontSize: "1.8rem", color: primaryTextColor(themeType) },
			h4: { fontWeight: 400, fontSize: "1.6rem", color: primaryTextColor(themeType) },
			h5: { fontWeight: 400, fontSize: "1.35rem", color: primaryTextColor(themeType) },
			h6: { fontWeight: 400, fontSize: "1.20rem", color: primaryTextColor(themeType) },
			subtitle1: { fontWeight: 400, fontSize: "1.0rem", color: secondaryTextColor(themeType) },
			subtitle2: { fontWeight: 400, fontSize: "0.9rem", color: secondaryTextColor(themeType) },
		},
		components: {
			MuiButtonBase: {
				defaultProps: {
					disableRipple: true, // No more ripple across the whole application!
				},
				styleOverrides: {
					root: {
						"&.MuiListItemButton-root": {
							"&.Mui-focusVisible": {
								border: "2px solid " + primaryTextColor(themeType),
								boxShadow: "inset 0 0 0 1px " + primaryTextColor(themeType),
							},
						},
						"&.MuiButton-root.MuiButton-text": {
							"&.Mui-focusVisible": {
								border: "2px solid " + primaryTextColor(themeType),
								boxShadow: "inset 0 0 0 1px " + primaryTextColor(themeType),
							},
						},
					},
				},
			},
			MuiIconButton: {
				styleOverrides: {
					root: {
						border: "1px solid transparent",
						color: secondaryTextColor(themeType),
						"&:hover": {
							backgroundColor: iconButtonBackgroundHover(themeType),
							color: primaryTextColor(themeType),
							border: "1px solid " + primaryTextColor(themeType),
						},
						"&:focus": {
							border: "1px solid " + primaryColor(themeType),
							boxShadow: "inset 0 0 0 1px " + primaryColor(themeType),
						},
					},
				},
			},
			MuiButton: {
				defaultProps: {
					disableRipple: true, // No more ripple, on the whole application!
				},
				styleOverrides: {
					startIcon: { paddingLeft: 10 },
					outlined: {
						border: "1px solid " + quaternaryTextColor(themeType),
						color: primaryTextColor(themeType),
						"&:hover": {
							backgroundColor: normalButtonBackgroundHover(themeType),
							color: normalButtonTextHover(themeType),
							border: "1px solid " + primaryTextColor(themeType),
						},
						"&:focus": {
							border: "1px solid " + primaryColor(themeType),
							boxShadow: "inset 0 0 0 1px " + primaryColor(themeType),
						},
					},
					contained: {
						"&:focus": {
							border: "2px solid " + primaryTextColor(themeType),
							boxShadow: "inset 0 0 0 1px " + primaryTextColor(themeType),
						},
					},
				},
			},

			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						backgroundColor: inputBoxBackground(themeType),
						fontSize: "1.5rem",
						lineHeight: 1,
						padding: "0.3rem 0.5rem",
					},
				},
			},
			MuiInputLabel: {
				styleOverrides: {
					root: {
						lineHeight: 1,
						fontSize: "1.2rem",
					},
				},
			},
			MuiInputAdornment: {
				styleOverrides: {
					root: {
						color: "inherit",
					},
				},
			},
			MuiFormHelperText: {
				styleOverrides: {
					root: {
						overflowWrap: "break-word",
					},
				},
			},
			MuiTypography: {
				styleOverrides: {
					root: {
						fontWeight: "inherit",
					},
				},
			},
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						textAlign: "center",
						fontSize: "0.9rem",
						fontWeight: 400,
						color: isDarkTheme(themeType) ? "#FEFEFE" : black,
						backgroundColor: isDarkTheme(themeType) ? "#0a0a0a" : "#F3F3F3",
						border: "1px solid " + (isDarkTheme(themeType) ? "#CCC" : "#444"),
						borderRadius: 8,
						padding: 12,
					},
				},
			},
		},
	});
	theCurrentTheme = responsiveFontSizes(theCurrentTheme);
	return theCurrentTheme;
};
