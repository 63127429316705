import Axios from "axios";
import * as Actions from "../store/Actions";
import EnvConfig from "../utils/EnvConfig";
import { LogAPI, LogAPIBody, LogDebug, LogTime, toTitleCaseFiltered } from "../utils/Utils";
import ApiEndpoints from "./ApiEndpoints";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Registers dayJs plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const statusNotFound = "Api call did not return a status.";

//this is for our calls to our backend. customapi is their calls to thier own custom endpoints from a widget / page / global
const ApiMiddleware = (store) => (next) => (action) => {
	next(action);
	if (action.type !== Actions.API) return;

	const storeState = store.getState();
	const dispatch = store.dispatch;
	//const { board } = storeState.boardData;
	const { token, boardid, campusid } = storeState.authData;

	const { apiEndpoint, body, onSuccess, onFailure, setBusy, extraProps } = action.payload;

	const finalURL = EnvConfig.apiBaseURL + apiEndpoint;
	let theHeaders = getHeaders(token, boardid, campusid);
	let theBody = body;

	extraProps && extraProps.logRequest && LogAPI("*** ApiCall Url: " + finalURL);
	extraProps && extraProps.logRequest && LogAPIBody(theBody);
	setBusy && setBusy(true);

	const showErrorToast = extraProps && !extraProps.hideErrorToast; //error toast shown by default, pass hideErrorToast prop (use this when using formError alert instead)
	const showSuccessToast = extraProps && extraProps.successMessage; //if pass a success message than show it
	const toastTitle = extraProps && extraProps.toastTitle ? toTitleCaseFiltered(extraProps.toastTitle) : "";
	const setFormErrorMessage = extraProps && extraProps.setFormErrorMessage ? extraProps.setFormErrorMessage : () => {};
	const isDelete = extraProps && extraProps.isDelete; //used when the api calling view ends up getting unmounted & setBusy is no longer accessible

	const timeLogText = "ApiCall Time: " + apiEndpoint + " | " + Date.now();
	LogTime(timeLogText);

	Axios.post(finalURL, theBody, {
		headers: theHeaders,
	})
		.then(({ data }) => {
			LogTime(timeLogText, true);
			extraProps && extraProps.logResponse && LogAPIBody(data);

			if (!data || !data.status) {
				showErrorToast &&
					dispatch(
						Actions.enqueueSnackbar({
							message: statusNotFound,
							title: toastTitle,
							excludeFromHistory: true,
							options: { variant: "error" },
						})
					);
				onFailure && onFailure(statusNotFound, "SERVER");
				isDelete && setBusy && setBusy(false);
				setFormErrorMessage(statusNotFound);
				LogAPI(statusNotFound);
			} else if (data.status === "OK") {
				if (apiEndpoint === ApiEndpoints.Logout) {
					dispatch(Actions.logoutBoard());
					onSuccess && onSuccess(data);
					return;
				} else if (apiEndpoint === ApiEndpoints.GetAccessType) {
					dispatchIndividualUpdate(dispatch, data, "access", Actions.UPDATE_AUTH_ACCESSTYPE);
				} else if (apiEndpoint === ApiEndpoints.LoginWithPassword) {
					dispatchIndividualUpdate(dispatch, data, "token", Actions.UPDATE_AUTH_TOKEN);
				} else if (apiEndpoint === ApiEndpoints.GetBoard) {
					dispatchIndividualUpdate(dispatch, data, "board", Actions.UPDATE_BOARD);
				}

				showSuccessToast &&
					dispatch(
						Actions.enqueueSnackbar({
							message: extraProps.successMessage,
							title: toastTitle,
							options: { variant: "success" },
						})
					);
				onSuccess && onSuccess(data); //callback function
			} else if (data.status === "ERROR") {
				//LogDebug(data.reason);
				if (data.reason && data.reason.includes("TOKEN")) dispatch(Actions.clearToken());
				showErrorToast &&
					dispatch(
						Actions.enqueueSnackbar({
							message: data.message,
							title: toastTitle,
							excludeFromHistory: true,
							options: { variant: "error" },
						})
					);
				onFailure && onFailure(data.message, data.reason);
				isDelete && setBusy && setBusy(false);
				setFormErrorMessage(data.message);
				LogAPI(data.message);
			}
		})
		.catch((error) => {
			const errorMessage = error && error.message ? error.message : "API Server Error.";
			const errorReason = error && error.reason ? error.reason : "";
			LogAPI(error);
			LogTime(timeLogText, true);
			showErrorToast &&
				dispatch(
					Actions.enqueueSnackbar({
						message: errorMessage,
						title: toastTitle,
						excludeFromHistory: true,
						options: { variant: "error" },
					})
				);
			onFailure && onFailure(errorMessage, errorReason); //callback function
			isDelete && setBusy && setBusy(false);
			setFormErrorMessage(errorMessage);
		})
		.finally(() => {
			if (!isDelete && setBusy) setBusy(false);
		});
};

const dispatchIndividualUpdate = (dispatch, data, dataKey, actionType) => {
	if (data[dataKey]) dispatch(Actions.updateIndividualData(data[dataKey], dataKey, actionType));
};

const getHeaders = (token, campusid, boardid) => {
	return {
		"Content-Type": "application/json",
		token: "" + token ?? "",
		campusid: "" + campusid ?? 0,
		boardid: "" + boardid ?? 0,
	};
};

export default ApiMiddleware;
