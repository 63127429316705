import React, { useState } from "react";
import { LogDebug } from "../utils/Utils";
import { Fade } from "@mui/material";
import EnvConfig from "../utils/EnvConfig";
import useInterval from "../utils/hooks/useInterval";

const getArrayOfImageUrls = (v) => {
	let array = [];
	if (!v) return array;

	if (v.extra_string1.length > 0) array.push(v.extra_string1);
	if (v.extra_string2.length > 0) array.push(v.extra_string2);
	if (v.extra_string3.length > 0) array.push(v.extra_string3);
	if (v.extra_string4.length > 0) array.push(v.extra_string4);
	if (v.extra_string5.length > 0) array.push(v.extra_string5);
	if (v.extra_string6.length > 0) array.push(v.extra_string6);

	return array;
};

const getNextIndex = (array, currentIndex) => {
	if (!array || array.length === 0 || currentIndex === -1) return -1;
	//at the end of array so return the first index
	if (array.length - 1 === currentIndex) return 0;
	else return currentIndex + 1;
};

const ImageCarousel = ({ classes, view: v }) => {
	const [urlsArray, setUrlsArray] = useState(() => getArrayOfImageUrls(v));
	const [currentIndex, setCurrentIndex] = useState(() => {
		if (!urlsArray.length === 0) return -1;
		else return 0;
	});
	const [fadeIn, setFadeIn] = useState(true);
	const [seconds, setSeconds] = useState(0);

	useInterval(() => {
		if (fadeIn === false && seconds % v.extra_int1 === 0) {
			// time is up change image and fade in
			setCurrentIndex(getNextIndex(urlsArray, currentIndex));
			setFadeIn(true);
		} else if (fadeIn && seconds > 1 && seconds % v.extra_int1 === v.extra_int1 - 2) {
			// image is diplayed time to fade out 2 seconds left
			setFadeIn(false);
		}

		setSeconds(seconds + 1);
	}, 1000);

	if (!v) return <></>;

	const currentImageUrl = currentIndex >= 0 || urlsArray.length > currentIndex ? urlsArray[currentIndex] : undefined;
	if (!currentImageUrl) return <></>;

	return (
		<Fade in={fadeIn} timeout={2000}>
			<div className={classes.imageCarouselPrent}>
				<img className={classes.imageCarouselImg} src={EnvConfig.baseImageURL + currentImageUrl} alt={v.name} />
			</div>
		</Fade>
	);
};

export default ImageCarousel;
