import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";
import clsx from "clsx";
import { LogDebug } from "../utils/Utils";
import Constants from "../utils/Constants";
import { Typography, Grid } from "@mui/material";
import useContainerDimensions from "../utils/hooks/useContainerDimensions";
import { getLocationsFromBoardData } from "../utils/BoardUtils";

const getWaitDisplay = (l) => {
	if (l.is_currently_takeout_open === 0 && l.is_currently_delivery_open === 0) return "Not Accepting Mobile Orders";

	return "" + l.estimated_wait_time + " mins";
};

const getQueueDisplay = (l) => {
	if (l.virtualqueue === 1) {
		if (l.is_currently_takeout_open === 0) return "Not Accepting Reservations";
		return "Capacity: " + l.order_queue_count + " / " + l.virtualqueue_max_capacity;
	}
	if (l.is_currently_takeout_open === 0) return "";

	return "Queue: " + l.order_queue_count;
};

const numCols = Constants.viewNumCols; //60 divisible by 1 to 6

//need to play with breakpoints here to get it right
const getColWidth = (width) => {
	if (width <= 0) return numCols; //1 col
	if (width < 1000) return numCols; //1 col
	if (width < 1400) return numCols / 2; //2 cols
	if (width < 1800) return numCols / 3; //3 cols
	if (width < 2200) return numCols / 4; //4 cols
	if (width < 2600) return numCols / 5; //5 cols
	return numCols / 6; //6 cols
};

const LocationWaitTimes = ({ classes, boardData, view }) => {
	const gridRef = useRef();
	const { width, height } = useContainerDimensions(gridRef);
	//since this component can be a % width of the overall screen, we cant rely on the Grid item column sizing since it is
	//calculated based on the overall screen width instead of the components width

	const locations = getLocationsFromBoardData(boardData, view);

	if (!locations || locations.length === 0) return <>NO LOCATIONS CONFIGURED</>;

	LogDebug("LocationWaitTimes view rendered");

	const colWidth = getColWidth(width);
	const singleRow = numCols / colWidth >= locations?.length;

	return (
		<Grid
			ref={gridRef}
			container
			columns={numCols}
			spacing={"0.8rem"}
			justifyContent={singleRow ? "center" : undefined}
			alignContent={"flex-start"}
		>
			{width > 0 &&
				locations.map((l, idx) => {
					return (
						<Grid item key={idx} xs={colWidth} className={classes.locationListCellGrid}>
							<div className={classes.locationListCellDiv}>
								<div className={classes.locationListIconAndNameDiv}>
									<img
										className={clsx(classes.locationIconRoundedSquareImage)}
										src={l.icon_picture_url_with_base}
										alt={l.name + " Icon"}
										aria-label={l.name + " Icon"}
									/>
									<Typography component={"div"} className={classes.locationListCellName}>{`${l.name}`}</Typography>
								</div>

								<div className={classes.locationListCellSubtextDiv}>
									{getQueueDisplay(l)?.length > 0 && (
										<Typography component={"div"} className={classes.locationListCellSubtext}>
											{getQueueDisplay(l)}
										</Typography>
									)}
									{l.virtualqueue === 0 && (
										<Typography component={"div"} className={classes.locationListCellSubtext}>
											{getWaitDisplay(l)}
										</Typography>
									)}
								</div>
							</div>
						</Grid>
					);
				})}
		</Grid>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(LocationWaitTimes);
