import React from "react";
import { Typography } from "@mui/material";
import { getVersionInfo } from "../utils/EnvUtils";

const FooterView = ({ classes }) => {
	const versionString = getVersionInfo();

	return (
		<div className={classes.footerDiv}>
			<Typography className={classes.footerText} variant="caption" display="block" color={"textPrimary"}>
				{versionString}
			</Typography>
			<Typography className={classes.footerText} variant="caption" display="block" color={"textPrimary"}>
				{"© 2022 Hangry Solutions Inc. All Rights Reserved."}
			</Typography>
		</div>
	);
};

export default FooterView;
