import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { LogDebug } from "../utils/Utils";
import { Typography } from "@mui/material";

const styleIframe = { width: "100%", height: "100%" };

//need to find a url that works with the Content-Security-Policy --> 'refused to connect'
const IFrameURL = ({ classes, view: v }) => {
	if (!v) return <></>;

	return <iframe id={"iframe-" + v.viewid} title={v.name} src={v.iframe_html} frameBorder="0" style={styleIframe} />;
};

export default IFrameURL;
