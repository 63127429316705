import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { LogDebug } from "../utils/Utils";
import Constants from "../utils/Constants";
import { getViewHeight, getViewWidth, ScreenLayoutTypes } from "../utils/BoardUtils";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

import View from "./ViewContainer";

const getViewFromID = (screen, theID) => {
	const array = screen?.views;
	if (!array) return undefined;
	const viewObj = array.find((v) => {
		return v.viewid === theID;
	});
	return viewObj;
};

const numCols = Constants.globalNumCols;

const ScreenLayout = ({ screen: s, outterPadding, classes }) => {
	if (!s) return <></>;

	const view1 = getViewFromID(s, s.viewid_1 || 0);
	const view2 = getViewFromID(s, s.viewid_2 || 0);
	const view3 = getViewFromID(s, s.viewid_3 || 0);
	const view4 = getViewFromID(s, s.viewid_4 || 0);
	const view5 = getViewFromID(s, s.viewid_5 || 0);
	const view6 = getViewFromID(s, s.viewid_6 || 0);

	const baseGridClasses = { className: classes.viewGridBorder };

	const baseViewProps = { screen: s, classes: classes };
	const viewProps1 = { view: view1, ...baseViewProps };
	const viewProps2 = { view: view2, ...baseViewProps };
	const viewProps3 = { view: view3, ...baseViewProps };
	const viewProps4 = { view: view4, ...baseViewProps };
	const viewProps5 = { view: view5, ...baseViewProps };
	const viewProps6 = { view: view6, ...baseViewProps };

	LogDebug("ScreenLayout view rendered");

	const heightStyle1 = { style: { height: getViewHeight(s, 1) } };
	const heightStyle2 = { style: { height: getViewHeight(s, 2) } };
	const heightStyle3 = { style: { height: getViewHeight(s, 3) } };
	const heightStyle4 = { style: { height: getViewHeight(s, 4) } };
	const heightStyle5 = { style: { height: getViewHeight(s, 5) } };
	const heightStyle6 = { style: { height: getViewHeight(s, 6) } };

	const widthCols1 = getViewWidth(s, 1);
	const widthCols2 = getViewWidth(s, 2);
	const widthCols3 = getViewWidth(s, 3);
	const widthCols4 = getViewWidth(s, 4);
	const widthCols5 = getViewWidth(s, 5);
	const widthCols6 = getViewWidth(s, 6);

	const innerGridFullHeight = { style: { height: "100%" } };

	const GridParent = ({ children }) => (
		<div className={classes.screenLayoutContainer} style={{ padding: `${outterPadding ?? 0}px` }}>
			<Grid container columns={numCols} spacing={0}>
				{children}
			</Grid>
		</div>
	);

	if (s.layout_type === ScreenLayoutTypes.kSingleScreenView) {
		return (
			<GridParent>
				<View {...viewProps1} />
			</GridParent>
		);
	}

	if (s.layout_type === ScreenLayoutTypes.kSplitScreenLandscape) {
		return (
			<GridParent>
				<Grid item xs={numCols} {...heightStyle1} {...baseGridClasses}>
					<View {...viewProps1} />
				</Grid>
				<Grid item xs={numCols} {...heightStyle2} {...baseGridClasses}>
					<View {...viewProps2} />
				</Grid>
			</GridParent>
		);
	}

	if (s.layout_type === ScreenLayoutTypes.kSplitScreenPortrait) {
		return (
			<GridParent>
				<Grid item xs={widthCols1} {...baseGridClasses}>
					<View {...viewProps1} />
				</Grid>
				<Grid item xs={widthCols2} {...baseGridClasses}>
					<View {...viewProps2} />
				</Grid>
			</GridParent>
		);
	}

	if (s.layout_type === ScreenLayoutTypes.kTriScreenPortrait) {
		return (
			<GridParent>
				<Grid item xs={widthCols1} {...baseGridClasses}>
					<View {...viewProps1} />
				</Grid>
				<Grid item xs={widthCols2} {...baseGridClasses}>
					<View {...viewProps2} />
				</Grid>
				<Grid item xs={widthCols3} {...baseGridClasses}>
					<View {...viewProps3} />
				</Grid>
			</GridParent>
		);
	}

	if (s.layout_type === ScreenLayoutTypes.kTriScreenLandscape) {
		return (
			<GridParent>
				<Grid item xs={numCols} {...heightStyle1} {...baseGridClasses}>
					<View {...viewProps1} />
				</Grid>
				<Grid item xs={numCols} {...heightStyle2} {...baseGridClasses}>
					<View {...viewProps2} />
				</Grid>
				<Grid item xs={numCols} {...heightStyle3} {...baseGridClasses}>
					<View {...viewProps3} />
				</Grid>
			</GridParent>
		);
	}

	if (s.layout_type === ScreenLayoutTypes.kTriScreenBottomOneTwoPortrait) {
		return (
			<GridParent>
				<Grid item xs={widthCols2} {...heightStyle2} {...baseGridClasses}>
					<View {...viewProps2} />
				</Grid>
				<Grid item xs={widthCols3} {...heightStyle2} {...baseGridClasses}>
					<View {...viewProps3} />
				</Grid>
				<Grid item xs={numCols} {...heightStyle1} {...baseGridClasses}>
					<View {...viewProps1} />
				</Grid>
			</GridParent>
		);
	}

	if (s.layout_type === ScreenLayoutTypes.kTriScreenTopOneTwoPortrait) {
		return (
			<GridParent>
				<Grid item xs={numCols} {...heightStyle1} {...baseGridClasses}>
					<View {...viewProps1} />
				</Grid>
				<Grid item xs={widthCols2} {...heightStyle2} {...baseGridClasses}>
					<View {...viewProps2} />
				</Grid>
				<Grid item xs={widthCols3} {...heightStyle2} {...baseGridClasses}>
					<View {...viewProps3} />
				</Grid>
			</GridParent>
		);
	}

	if (s.layout_type === ScreenLayoutTypes.kTriScreenLeftSideOneTwoLandscape) {
		return (
			<GridParent>
				<Grid item xs={widthCols1} {...baseGridClasses}>
					<View {...viewProps1} />
				</Grid>
				<Grid item xs={widthCols2} {...baseGridClasses}>
					<Grid container columns={numCols} spacing={0} {...innerGridFullHeight}>
						<Grid item xs={numCols} {...heightStyle2} {...baseGridClasses}>
							<View {...viewProps2} />
						</Grid>
						<Grid item xs={numCols} {...heightStyle3} {...baseGridClasses}>
							<View {...viewProps3} />
						</Grid>
					</Grid>
				</Grid>
			</GridParent>
		);
	}

	if (s.layout_type === ScreenLayoutTypes.kTriScreenRightSideOneTwoLandscape) {
		return (
			<GridParent>
				<Grid item xs={widthCols2} {...baseGridClasses}>
					<Grid container spacing={0} {...innerGridFullHeight}>
						<Grid item xs={numCols} {...heightStyle2} {...baseGridClasses}>
							<View {...viewProps2} />
						</Grid>
						<Grid item xs={numCols} {...heightStyle3} {...baseGridClasses}>
							<View {...viewProps3} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={widthCols1} {...heightStyle1} {...baseGridClasses}>
					<View {...viewProps1} />
				</Grid>
			</GridParent>
		);
	}

	if (s.layout_type === ScreenLayoutTypes.kQuadScreenEqualSquares) {
		return (
			<GridParent>
				<Grid item xs={widthCols1} {...heightStyle1} {...baseGridClasses}>
					<View {...viewProps1} />
				</Grid>
				<Grid item xs={widthCols2} {...heightStyle2} {...baseGridClasses}>
					<View {...viewProps2} />
				</Grid>
				<Grid item xs={widthCols3} {...heightStyle3} {...baseGridClasses}>
					<View {...viewProps3} />
				</Grid>
				<Grid item xs={widthCols4} {...heightStyle4} {...baseGridClasses}>
					<View {...viewProps4} />
				</Grid>
			</GridParent>
		);
	}

	if (s.layout_type === ScreenLayoutTypes.kQuadScreenEqualMiddleTopBottomHorizontal) {
		return (
			<GridParent>
				<Grid item xs={numCols} {...heightStyle1} {...baseGridClasses}>
					<View {...viewProps1} />
				</Grid>
				<Grid item xs={numCols} {...heightStyle3} {...baseGridClasses}>
					<Grid container columns={numCols} spacing={0} {...innerGridFullHeight}>
						<Grid item xs={widthCols3} {...baseGridClasses}>
							<View {...viewProps3} />
						</Grid>
						<Grid item xs={widthCols4} {...baseGridClasses}>
							<View {...viewProps4} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={numCols} {...heightStyle2} {...baseGridClasses}>
					<View {...viewProps2} />
				</Grid>
			</GridParent>
		);
	}

	if (s.layout_type === ScreenLayoutTypes.kQuadScreenEqualMiddleLeftRightVertical) {
		return (
			<GridParent>
				<Grid item xs={widthCols1} {...heightStyle1} {...baseGridClasses}>
					<View {...viewProps1} />
				</Grid>
				<Grid item xs={widthCols3} {...baseGridClasses}>
					<Grid container columns={numCols} spacing={0} {...heightStyle3}>
						<Grid item xs={numCols} {...baseGridClasses} {...innerGridFullHeight}>
							<View {...viewProps3} />
						</Grid>
						<Grid item xs={numCols} {...baseGridClasses}>
							<View {...viewProps4} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={widthCols2} {...heightStyle2} {...baseGridClasses}>
					<View {...viewProps2} />
				</Grid>
			</GridParent>
		);
	}

	if (s.layout_type === ScreenLayoutTypes.kQuadScreenThreeMiddleOneBottom) {
		return (
			<GridParent>
				<Grid item xs={widthCols2} {...heightStyle2} {...baseGridClasses}>
					<View {...viewProps2} />
				</Grid>
				<Grid item xs={widthCols3} {...heightStyle3} {...baseGridClasses}>
					<View {...viewProps3} />
				</Grid>
				<Grid item xs={widthCols4} {...heightStyle4} {...baseGridClasses}>
					<View {...viewProps4} />
				</Grid>
				<Grid item xs={numCols} {...heightStyle1} {...baseGridClasses}>
					<View {...viewProps1} />
				</Grid>
			</GridParent>
		);
	}

	if (s.layout_type === ScreenLayoutTypes.kQuadScreenThreeMiddleOneTop) {
		return (
			<GridParent>
				<Grid item xs={numCols} {...heightStyle1} {...baseGridClasses}>
					<View {...viewProps1} />
				</Grid>
				<Grid item xs={widthCols2} {...heightStyle2} {...baseGridClasses}>
					<View {...viewProps2} />
				</Grid>
				<Grid item xs={widthCols3} {...heightStyle3} {...baseGridClasses}>
					<View {...viewProps3} />
				</Grid>
				<Grid item xs={widthCols4} {...heightStyle4} {...baseGridClasses}>
					<View {...viewProps4} />
				</Grid>
			</GridParent>
		);
	}
};

export default ScreenLayout;

//this component handles the type of layout to be used (single, split, triple, etc.)
//it will create the 'view' for each part of the screen layout
