import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { LogDebug } from "../utils/Utils";
import ScreenLayout from "./ScreenLayout";

const ScreenContainer = ({ classes, screen, numberOfScreens, outterPadding, isVisible, doGoToNextScreen }) => {
	useEffect(() => {
		if (!isVisible) return;
		if (numberOfScreens === 1) return; //do not cycle if only 1 screen
		//need to handle case where it is 0
		//timer length set from screen object
		const durationSecs = screen?.duration_seconds > 0 ? screen.duration_seconds : 60;
		//runs once
		setTimeout(doGoToNextScreen, durationSecs * 1000);
	}, [isVisible]);

	if (!screen) return <></>;

	//might handle this case by keeping in DOM off screen / preloading. see how the performance is first
	if (!isVisible) return <></>;

	LogDebug("ScreenContainer view rendered");

	return <ScreenLayout screen={screen} outterPadding={outterPadding} classes={classes} />;
};

export default ScreenContainer;

//this container manges the screen duration timer
//it also could be used to allow pre-loading a screen offscreen?
