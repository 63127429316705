import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjsIsBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { Log, LogDebug } from "./Utils";
import Constants from "./Constants";

// Registers dayJs plugins
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(dayjsIsBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

//use this function instead of calling dayjs() elsewhere
///we should not have to import dayjs anywhere else other than this file
export const getNowDate = () => {
	return dayjs();
};

export const getDateFormats = (d) => {
	const dayjsObj = d;
	const localDateSql = d.format("YYYY-MM-DD");
	const localDateTimeSql = d.format("YYYY-MM-DD hh:mm:ss");
	const localDateMonthDayYear = d.format("MMM. D, YYYY");
	const localDateMonthDay = d.format("MMM. D");
	const localDateTimeMonthDayHourMin = d.format("MMM. D, h:mm A");
	const localTimeHourMin = d.format("h:mm A");
	const localDateTimeMonthDayYearHourMin = d.format("MMM. D, YYYY") + " - " + d.format("h:mm A");
	const localDateDowMonthDayYear = d.format("ddd, MMM. Do, YYYY");
	const localDateDowMonthDay = d.format("ddd, MMM. Do");

	const localStartDateTimeSql = d.format("YYYY-MM-DD") + " 00:00:00";
	const localEndDateTimeSql = d.format("YYYY-MM-DD") + " 23:59:59";
	const localStartTimeDateMo = dayjs(localStartDateTimeSql);
	const localEndDateTimeMo = dayjs(localEndDateTimeSql);
	const utcStartDateTimeMo = dayjs.utc(localStartTimeDateMo);
	const utcEndDateTimeMo = dayjs.utc(localEndDateTimeMo);
	const utcStartDateTimeSql = utcStartDateTimeMo.format("YYYY-MM-DD HH:mm:ss");
	const utcEndDateTimeSql = utcEndDateTimeMo.format("YYYY-MM-DD HH:mm:ss");

	return {
		dayjsObj: dayjsObj,
		localDateSql: localDateSql,
		localDateTimeSql: localDateTimeSql,
		localDateMonthDayYear: localDateMonthDayYear,
		localDateMonthDay: localDateMonthDay,
		localDateTimeMonthDayHourMin: localDateTimeMonthDayHourMin,
		localTimeHourMin: localTimeHourMin,
		localDateTimeMonthDayYearHourMin: localDateTimeMonthDayYearHourMin,
		localDateDowMonthDayYear: localDateDowMonthDayYear,
		localDateDowMonthDay: localDateDowMonthDay,
		localStartDateTimeSql: localStartDateTimeSql,
		localEndDateTimeSql: localEndDateTimeSql,
		localStartTimeDateMo: localStartTimeDateMo,
		localEndDateTimeMo: localEndDateTimeMo,
		utcStartDateTimeMo: utcStartDateTimeMo,
		utcEndDateTimeMo: utcEndDateTimeMo,
		utcStartDateTimeSql: utcStartDateTimeSql,
		utcEndDateTimeSql: utcEndDateTimeSql
	};
};

export const getDateFormatsFromUTCString = (utcstring) => {
	if (!utcstring || utcstring.length === 0) return { localDateTimeSql: "" };
	const d = getDateObjFromUTCString(utcstring);
	return getDateFormats(d);
};

export const getDateObjFromUTCString = (utcstring) => {
	if (!utcstring || utcstring.length === 0) return undefined;
	let modifiedStr = utcstring;
	if (utcstring.length > 19) modifiedStr = utcstring.substring(0, 19); //incase sql returns ms like .231 at the end
	const d = dayjs(modifiedStr + " Z", "YYYY-MM-DD HH:mm:ss Z"); //UTC //need format prop to avoid console warnings
	return d;
};

export const getDateObjFromLocalDateOnlyString = (localDateString) => {
	if (!localDateString || localDateString.length === 0) return getNowDate();
	const d = dayjs(localDateString + " 12:00:00", "YYYY-MM-DD HH:mm:ss");
	return d;
};

export const getDateObjFromLocalString = (localstring) => {
	let modifiedStr = localstring;
	if (localstring.length > 19) modifiedStr = localstring.substring(0, 19); //incase sql returns ms like .231 at the end
	const d = dayjs(modifiedStr, "YYYY-MM-DD HH:mm:ss");
	return d;
};

export const getDateObjFromDateString = (localDateString) => {
	const d = dayjs(localDateString, "YYYY-MM-DD");
	return d;
};

export const getDateObjFromDateHourString = (localDateString) => {
	const d = dayjs(localDateString, "YYYY-MM-DD-HH");
	return d;
};

export const getDateObjFromISOString = (isoString) => {
	const d = dayjs(isoString);
	return d;
};

export const getDateObjFromTimeForToday = (timeString) => {
	if (!timeString) return getNowDate();
	const d = dayjs(timeString, "HH:mm");
	return d;
};

export const getNowDateObjStringForFileName = () => {
	const d = getNowDate();
	return d.format("YYYY-MM-DD") + "_" + d.format("hh-mm-A");
};

export const getMinutesBetweenDates = (endMo, startMo) => {
	const durationDiff = dayjs.duration(endMo.diff(startMo));
	const minutesDiff = Math.floor(durationDiff.asMinutes());
	return minutesDiff;
};

export const getSecondsBetweenDates = (endMo, startMo) => {
	const durationDiff = dayjs.duration(endMo.diff(startMo));
	const secondsDiff = Math.floor(durationDiff.asSeconds());
	return secondsDiff;
};

export const getDurationDiff = (endMo, startMo) => {
	return dayjs.duration(endMo.diff(startMo));
};

export const getDaysBetweenDates = (endMo, startMo) => {
	// const durationDiff = dayjs.duration(endMo.diff(startMo));
	// const minutesDiff = Math.floor(durationDiff.asMinutes());
	const diffDays = endMo.startOf("day").diff(startMo.startOf("day"), "days") + 1;
	return diffDays;
};

export const isDateSameAsToday = (inputDayjs) => {
	return getNowDate().isSame(inputDayjs, "day");
};

export const getDateObjFromLocalDayOnly = (inputDay, isStart) => {
	const time = isStart ? " 00:00:01" : " 23:59:59";
	const d = dayjs(inputDay + time, "YYYY-MM-DD HH:mm:ss");
	return d;
};

export const getDateObjFromWeekYear = (localDateString) => {
	const d = dayjs(localDateString, "WW-YYYY");
	return d;
};

export const shouldRefreshSectionData = (sectionMoment) => {
	if (!sectionMoment) return false;
	const minutesDiff = getMinutesBetweenDates(getNowDate(), sectionMoment);
	LogDebug("minutesDiff = " + minutesDiff);
	if (minutesDiff > Constants.sectionDataRefreshMinutes) return true;
	else return false;
};

export const getUTCDateTimeFromDateObj = (d) => {
	let utcStartDate = dayjs.utc(d);
	return utcStartDate.format("YYYY-MM-DD HH:mm:ss");
};

export const getUTCStartDateTimeFromDateObj = (startDayjs) => {
	let localStartDateStr = startDayjs.format("YYYY-MM-DD") + " 00:00:01";
	let localStartDate = dayjs(localStartDateStr);
	let utcStartDate = dayjs.utc(localStartDate);
	return utcStartDate.format("YYYY-MM-DD HH:mm:ss");
};

export const getUTCEndDateTimeFromDateObj = (endDayjs) => {
	let localEndDateStr = endDayjs.format("YYYY-MM-DD") + " 23:59:59";
	let localEndDate = dayjs(localEndDateStr);
	let utcEndDate = dayjs.utc(localEndDate);
	return utcEndDate.format("YYYY-MM-DD HH:mm:ss");
};

export const convertToStartOfDay = (d) => {
	const newMo = d.clone().set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0);
	// LogDebug(newMo);
	return newMo;
};

export const convertToEndOfDay = (d) => {
	const newMo = d.clone().set("hour", 23).set("minute", 59).set("second", 59).set("millisecond", 999);
	// LogDebug(newMo);
	return newMo;
};

export const guessBrowserTimezone = () => {
	return dayjs.tz.guess();
};

export const getPrettySecondsMinutesFromSeconds = (duration) => {
	// ~~ is Math.floor

	// Hours, minutes and seconds
	const hrs = ~~(duration / 3600);
	const mins = ~~((duration % 3600) / 60);
	const secs = ~~(duration % 60);

	let ret = "";

	if (hrs > 0) {
		ret += hrs + " hour" + (hrs > 1 ? "s" : "");
	}

	if (mins > 0) {
		if (ret.length > 0) ret += ", ";
		ret += mins + " minute" + (mins > 1 ? "s" : "");
	}

	if (secs > 0) {
		if (ret.length > 0) ret += ", ";
		ret += secs + " second" + (secs > 1 ? "s" : "");
	}

	return ret;
};
