import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";
import { LogDebug } from "../utils/Utils";
import QRCode from "react-qr-code";
import { useContainerDimensionsVariableHeader } from "../utils/hooks/useContainerDimensions";
import { getLocationsFromBoardData } from "../utils/BoardUtils";

const LocationCheckInQRCode = ({ classes, boardData, view, bodyDivRef }) => {
	const { width, height } = useContainerDimensionsVariableHeader(bodyDivRef.current);

	const [barcode, setBarcode] = useState(() => {
		const locations = getLocationsFromBoardData(boardData, view);
		const l = locations && locations.length > 0 ? locations[0] : undefined;
		return l?.checkin_barcode || "";
	});

	useEffect(() => {
		const locations = getLocationsFromBoardData(boardData, view);
		const l = locations && locations.length > 0 ? locations[0] : undefined;
		const newBarcode = l?.checkin_barcode || "";

		if (newBarcode !== barcode) setBarcode(newBarcode);
		//otherwise do nothing
	}, [boardData, view]);

	if (!barcode || barcode.length === 0) return <></>;

	const qrCodeSize = Math.min(width || 800, height || 800, 800);
	const pictureAdjustment = qrCodeSize === height ? 45 : 40;

	LogDebug("LocationCheckInQRCode view rendered");

	LogDebug(qrCodeSize);
	LogDebug(pictureAdjustment);
	return (
		<div className={classes.locationQRCodeContainer}>
			{qrCodeSize > 0 && <QRCode value={barcode} level="Q" size={qrCodeSize - pictureAdjustment} />}
		</div>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(LocationCheckInQRCode);
