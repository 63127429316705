import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { LogDebug } from "../utils/Utils";
import { Typography } from "@mui/material";

const styleIframe = { width: "100%", height: "100%" };

const IFrameHtml = ({ classes, view: v }) => {
	if (!v) return <></>;

	return <iframe id={"iframe-" + v.viewid} title={v.name} srcDoc={v.iframe_html} frameBorder="0" style={styleIframe} />;
};

export default IFrameHtml;
