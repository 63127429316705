import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";
import clsx from "clsx";
import { LogDebug, maxStringLength } from "../utils/Utils";
import Constants from "../utils/Constants";
import { Divider, Grid, Typography, Card, CardContent, CardMedia } from "@mui/material";

import useInterval from "../utils/hooks/useInterval";
import useContainerDimensions from "../utils/hooks/useContainerDimensions";
import { currencyFormatter } from "../utils/FormatUtil";
import { getMenuSectionsFromMenu, getMenuItemsFromMenuSection } from "../utils/MenuUtils";
import { getLocationsFromBoardData } from "../utils/BoardUtils";

const numCols = 60; //60 divisible by 1 to 6

//need to play with breakpoints here to get it right
const getColWidth = (width, v) => {
	if (!v || v.extra_int6 === 0) {
		if (width <= 0) return numCols; //1 col
		if (width < 800) return numCols; //1 col
		if (width < 2000) return numCols / 2; //2 cols
		return numCols / 3; //3 cols default
	} else {
		//single section view
		if (width <= 0) return numCols;
		if (width < 800) return numCols / 2;
		if (width < 1200) return numCols / 3;
		if (width < 1600) return numCols / 3;
		if (width < 2000) return numCols / 4;
		return numCols / 4; //default
	}
};

const getLocSec = (locations) => {
	let tempLocSec = [];
	locations?.forEach((loc, index) => {
		let tempObj = {};
		const sections = getMenuSectionsFromMenu(loc.menu);
		tempObj["index"] = index;
		tempObj["sections"] = sections;
		tempObj["sectionLength"] = sections.length;
		tempLocSec.push(tempObj);
	});
	return tempLocSec;
};

const LocationMenu = ({ classes, view: v, boardData, screen }) => {
	const gridRef = useRef();
	const { width, height } = useContainerDimensions(gridRef);
	const colWidth = getColWidth(width, v);

	const [locSec, setLocSec] = useState(() => getLocSec(getLocationsFromBoardData(boardData, v) || []));

	useEffect(() => {
		const locations = getLocationsFromBoardData(boardData, v);
		setLocSec(getLocSec(locations) || []);
	}, [boardData, v]);

	const [currLocIdx, setCurrLocIdx] = useState(() => {
		if (!locSec.length === 0) return -1;
		else return 0;
	});
	const [currSectionIdx, setCurrSectionIdx] = useState(() => {
		if (!locSec.length === 0) return -1;
		else return 0;
	});

	const getColCount = () => {
		if (v?.extra_int6 === 1) return 1;
		return numCols / colWidth;
	};
	const [colCount, setColCount] = useState(() => getColCount());

	const getNumRows = () => {
		let tmpRows = 1;
		if (screen?.layout_type === 21) {
			tmpRows = 2;
		} else if (screen?.layout_type === 31) {
			tmpRows = 3;
		}

		return tmpRows;
	};
	const [numRows, setNumRows] = useState(() => getNumRows());

	const getNextIndex = (currLocIdx, currSectionIdx) => {
		if (!locSec || locSec.length === 0 || currLocIdx === -1) {
			// precautionary if locations is not available
			setCurrLocIdx(-1);
			setCurrSectionIdx(-1);
			return;
		}

		if (locSec[currLocIdx].sectionLength - 1 <= currSectionIdx) {
			//at the end of locSec so return the first index
			setCurrLocIdx(locSec.length - 1 === currLocIdx ? 0 : currLocIdx + 1);
			setCurrSectionIdx(0);
		} else {
			if (locSec[currLocIdx].sectionLength - (currSectionIdx + colCount) > 0) {
				setCurrSectionIdx(currSectionIdx + colCount);
			} else if (locSec[currLocIdx].sectionLength - (currSectionIdx + colCount) === 0) {
				setCurrLocIdx(locSec.length - 1 === currLocIdx ? 0 : currLocIdx + 1);
				setCurrSectionIdx(0);
			} else {
				setCurrLocIdx(locSec.length - 1 === currLocIdx ? 0 : currLocIdx + 1);
				setCurrSectionIdx(0);
			}
		}
	};

	useInterval(() => {
		getNextIndex(currLocIdx, currSectionIdx);
	}, v.extra_int1 * 1000);

	const getMutlipleSections = () => {
		if (!locSec || locSec.length === 0) return [];

		let tmpArr = [];
		for (let idx = 0; idx < colCount; idx++) {
			// limit to colCount sections
			let section = locSec[currLocIdx].sections[currSectionIdx + idx];
			if (!section) break;

			let sectionTitleStyle = undefined;
			if (v?.extra_string1?.length === 6) {
				sectionTitleStyle = { background: `#${v.extra_string1}`, color: "#FFF" };
			}

			tmpArr.push(
				<Grid
					item
					key={section.sectionid}
					xs={colWidth}
					className={clsx(classes.locationMenuSectionGroup, {
						[classes.locationMenuSectionGroupPortraitNoHeader]: numRows === 1 && !v.heading && !v.subheading,
						[classes.locationMenuSectionGroupPortraitHeaderOnly]: numRows === 1 && !!v.heading && !v.subheading,
						[classes.locationMenuSectionGroupPortraitSubheaderOnly]: numRows === 1 && !v.heading && !!v.subheading,
						[classes.locationMenuSectionGroupPortraitHeaderAndSubHeader]: numRows === 1 && !!v.heading && !!v.subheading,
						//[classes.locationMenuSectionGroupLandcape2]: numRows === 2,
						//[classes.locationMenuSectionGroupLandcape3]: numRows === 3,
					})}
				>
					<div
						key={`frag${section.id}`}
						className={clsx(classes.locationMenuSectionContainer, {
							[classes.locationMenuSectionContainerNoHeading]: !v.heading,
							[classes.locationMenuSectionContainerHeading]: !!v.heading,
						})}
					>
						<Typography className={classes.locationMenuSectionTitle} style={sectionTitleStyle}>
							{section.name.toUpperCase()}
						</Typography>
						{v?.extra_int5 === 1 && section.cover_picture_url_with_base?.length > 0 && (
							<img
								src={section.cover_picture_url_with_base}
								alt={section.name}
								className={classes.locationMenuSectionCoverImg}
							/>
						)}
						{getMenuItemsFromMenuSection(section).map((i, itemIdx) => (
							<React.Fragment key={itemIdx}>
								<div key={itemIdx} className={classes.locationMenuCellDiv}>
									{v?.extra_int4 === 1 && i.icon_picture_url_with_base?.length > 0 && (
										<div className={classes.locationMenuImageDiv}>
											<img
												src={i.icon_picture_url_with_base}
												alt={i.name}
												className={classes.locationMenuItemIconRoundedSquareImage}
											/>
										</div>
									)}

									<div className={classes.locationMenuIconAndNameParentDiv}>
										<div className={classes.locationMenuIconAndNameDiv}>
											<Typography component={"div"} className={classes.locationMenuCellName}>
												{`${i.name}`}
											</Typography>
											<Typography component={"div"} className={classes.locationMenuCellPrice}>
												{i?.price_display > 0 && `${currencyFormatter(i?.price_display || 0.0)}`}
											</Typography>
										</div>

										{v?.extra_int2 === 1 && i?.description && (
											<Typography component={"div"} className={classes.locationMenuCellDescription}>
												{`${i.description}`}
											</Typography>
										)}
										{v?.extra_int3 === 1 && (i?.nutrition_calories > 0 || i?.dietary_restrictions?.length > 0) && (
											<div style={{ height: "1.2rem", display: "flex" }}>
												<Typography component={"div"} className={clsx(classes.locationMenuCellCalories)}>
													{i?.nutrition_calories > 0 && (
														<div
															style={{ paddingRight: "15px", display: "inline-flex" }}
														>{`${i.nutrition_calories} Cals`}</div>
													)}

													{i?.dietary_restrictions?.length > 0 && (
														<div style={{ padding: "0", display: "inline-flex" }}>
															{i.dietary_restrictions?.map((d, idx_dr) => {
																return (
																	<img
																		key={idx_dr}
																		src={d.icon_url_with_base}
																		alt={d.label}
																		className={classes.locationMenuFlexCardDietaryFlagImg}
																	/>
																);
															})}
														</div>
													)}
												</Typography>
											</div>
										)}
									</div>
								</div>
								<Divider className={classes.locationMenuCellDivider} />
							</React.Fragment>
						))}
					</div>
				</Grid>
			);
		}
		return tmpArr;
	};

	const getSectionSingleCardsGrid = () => {
		if (!locSec || locSec.length === 0) return [];

		let tmpArr = [];
		let section = locSec[currLocIdx].sections[currSectionIdx];
		if (!section) return tmpArr;
		const items = getMenuItemsFromMenuSection(section);

		let sectionTitleStyle = undefined;
		if (v?.extra_string1?.length === 6) {
			sectionTitleStyle = { background: `#${v.extra_string1}`, color: "#FFF" };
		}

		const isIconImage = v?.extra_int4 === 1;
		const isCoverImage = v?.extra_int4 === 0;

		let cellHeight = 0;
		//for this style v?.extra_int4, 0=cover, 1=icon
		if (isCoverImage) {
			cellHeight = 310; // cover image
			if (v?.extra_int2 === 1) cellHeight = cellHeight + 130; //desc
			if (v?.extra_int3 === 1) cellHeight = cellHeight + 35; //cals
		} else if (isIconImage) {
			cellHeight = cellHeight + 90; // cover image
			if (v?.extra_int2 === 1) cellHeight = cellHeight + 100; //desc
			if (v?.extra_int3 === 1) cellHeight = cellHeight + 30; //cals
		}

		return (
			<div className={classes.locationMenuFlexDiv}>
				<div>
					<Typography className={classes.locationMenuFlexCardSectionTitle} style={sectionTitleStyle}>
						{section.name.toUpperCase()}
					</Typography>
				</div>
				<Grid
					container
					ref={gridRef}
					columns={numCols}
					spacing={0}
					justifyContent={"center"}
					className={classes.locationMenuFlexGridContainer}
				>
					{items?.map((i, idx) => (
						<Grid
							item
							xs={colWidth}
							key={idx}
							className={clsx(classes.locationMenuFlexGridItem, {
								[classes.locationMenuFlexGridItemWithIconImage]: isIconImage,
							})}
							style={{ height: cellHeight }}
						>
							<Card className={classes.locationMenuFlexCard}>
								<CardContent className={classes.locationMenuFlexCardContent}>
									{isCoverImage && (
										<CardMedia
											component="img"
											alt={`${i.name}`}
											height="auto"
											width="100%"
											image={i.cover_picture_url_with_base}
										/>
									)}
									<div style={{ display: "flex", height: isIconImage ? "100%" : undefined, alignItems: "center" }}>
										{isIconImage && i.icon_picture_url_with_base?.length > 0 && (
											<div className={classes.locationMenuImageDiv}>
												<img
													src={i.icon_picture_url_with_base}
													alt={i.name}
													className={classes.locationMenuFlexCardIconRoundedSquareImage}
												/>
											</div>
										)}
										<div className={classes.locationMenuFlexCardNamePriceParentDiv}>
											<div className={classes.locationMenuFlexCardNamePrice}>
												<Typography component={"div"} className={clsx(classes.locationMenuCellName)}>
													<span>{`${i.name}`}</span>
												</Typography>
												<Typography component={"div"} className={clsx(classes.locationMenuCellPrice)}>
													{i?.price_display > 0 && `${currencyFormatter(i?.price_display || 0.0)}`}
												</Typography>
											</div>
											<div className={classes.locationMenuFlexCardDescCals}>
												{v?.extra_int2 === 1 && (
													<>
														<Typography
															component={"div"}
															className={clsx(classes.locationMenuCellDescription)}
														>
															<span>{`${maxStringLength(i.description, 160, true)}`}</span>
														</Typography>
													</>
												)}
												{v?.extra_int3 === 1 &&
													(i?.nutrition_calories > 0 || i?.dietary_restrictions?.length > 0) && (
														<div style={{ height: "1.2rem", display: "flex" }}>
															<Typography
																component={"div"}
																className={clsx(classes.locationMenuFlexCardCellCalories)}
															>
																{i?.nutrition_calories > 0 && (
																	<div
																		style={{ paddingRight: "15px", display: "inline-flex" }}
																	>{`${i.nutrition_calories} Cals`}</div>
																)}

																{i?.dietary_restrictions?.length > 0 && (
																	<div style={{ padding: "0", display: "inline-flex" }}>
																		{i.dietary_restrictions?.map((d, idx_dr) => {
																			return (
																				<img
																					key={idx_dr}
																					src={d.icon_url_with_base}
																					alt={d.label}
																					className={
																						classes.locationMenuFlexCardDietaryFlagImg
																					}
																				/>
																			);
																		})}
																	</div>
																)}
															</Typography>
														</div>
													)}
											</div>
										</div>
									</div>
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>
			</div>
		);
	};

	const getMenuItems = (v) => {
		return (
			<div
				className={clsx(classes.locationMenuContainer, {
					[classes.locationMenuContainerSingle]: v?.extra_int6 === 1,
				})}
			>
				{v?.extra_int6 === 0 && (
					<Grid
						ref={gridRef}
						container
						columns={numCols}
						justifyContent={"space-evenly"}
						className={clsx(classes.locationMenuGridContainer, {
							[classes.locationMenuGridContainerNoHeading]: !v.heading && screen.views.length === 1 && screen.layout_type === 1,
							[classes.locationMenuGridContainerHeading]: !!v.heading,
							[classes.locationMenuGridContainerHeadingPortrait]:
								!v.heading && screen.views.length > 1 && (screen.layout_type === 20 || screen.layout_type === 30),
						})}
					>
						{width > 0 && getMutlipleSections()}
					</Grid>
				)}
				{v?.extra_int6 === 1 && getSectionSingleCardsGrid()}
			</div>
		);
	};

	useEffect(() => {
		setColCount(getColCount);
		setNumRows(getNumRows);
	});

	return <>{getMenuItems(v)}</>;
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(LocationMenu);
