import crypto from "crypto";
import EnvConfig from "./EnvConfig";
import { isProduction } from "./EnvUtils";
import { detect } from "detect-browser";
import Constants from "./Constants";
import { regExValidator } from "./ConstantsValidators";
import { guessBrowserTimezone } from "./DateUtils";

//Log to console
export const Log = (msg) => {
	if (!isProduction()) console.log(msg);
};

//includes stack of where the log was called from
export const LogTrace = (msg) => {
	if (!isProduction()) console.trace(msg);
};

export const LogArray = (msg) => {
	if (!isProduction()) console.table(msg);
};

//less important, more of a trace. need to change console to verbose to see these color coded blue
export const LogDebug = (msg) => {
	if (!isProduction()) console.debug(msg);
};

export const LogError = (msg) => {
	if (!isProduction()) console.error(msg);
};

//separate fx for api logging so that we can handle it differently based on env
export const LogAPIBody = (msg) => {
	if (!isProduction()) console.info(msg);
};

export const LogAPI = (msg) => {
	if (!isProduction()) console.info(msg);
};

//eventName must be unique so include Date.now() in name
export const LogTime = (eventName, isEnd) => {
	if (!isProduction()) {
		if (!isEnd) console.time(eventName);
		else console.timeEnd(eventName);
	}
};

export const getHashedPassword = (pass, salt) => {
	let hash = crypto.createHmac("sha512", salt);
	hash.update(pass);
	const value = hash.digest("hex");
	return value;
};

export const getHashedChecksumForApiCall = function (content, salt) {
	let hash = crypto.createHmac("sha256", salt);
	hash.update(content);
	const value = hash.digest("hex");
	return value;
};

//setFavIcon("/favicon.png");
//this.setFavIcon("https://cdn4.iconfinder.com/data/icons/new-google-logo-2015/400/new-google-favicon-128.png");
// export const setFavIcon(project) {
// 	let theIconUrl = "/favicon.png";
// 	document.querySelector("link[rel*='icon']").href = theIconUrl;
// }

export const setAppPageTitle = (title) => {
	let theTitle = title.length > 0 ? title + " | " + EnvConfig.websiteName : EnvConfig.websiteName;
	if (document.title !== theTitle) document.title = theTitle;
};

export const getBrowserInfoForInitialization = () => {
	let browser = undefined,
		language = "",
		timezone = "";
	try {
		language = navigator.language || navigator.userLanguage;
		browser = detect();
		timezone = guessBrowserTimezone();
	} catch {}

	return { appVersion: EnvConfig.version, deviceLanguage: language, timezone: timezone, browser: browser };
};

//intentionally allows sapces
export const scrubNonAlphaNumeric = (input) => {
	if (!input) return "";
	return input.replace(/[^a-z0-9 ]/gi, "");
};

export const scrubNonAlphaNumericUnderscore = (input) => {
	if (!input) return "";
	return input.replace(" ", "_").replace(/[^a-z0-9_]/gi, "");
};

export const scrubSpaces = (input) => {
	if (!input) return "";
	return input.replace(/\s/g, "");
};

export const scrubForFileName = (input) => {
	return scrubSpaces(scrubNonAlphaNumeric(input));
};

export const scrubUnsafeCharacters = (input) => {
	if (!input) return "";
	return input.replace(regExValidator.unsafeChars, "");
};

export const scrub = (input) => {
	if (!input) return "";
	else return input;
};

export const scrubNonZero = (input) => {
	return input === 0 ? 1 : input;
};

export const scrubNullTo0 = (input) => {
	if (input === undefined) return 0;
	return input;
};

export const doesContainNumber = (str) => {
	return /\d/.test(str);
};

export const doesContainLowerCase = (str) => {
	return /[a-z]/.test(str);
};

export const doesContainUpperCase = (str) => {
	return /[A-Z]/.test(str);
};

export const isValidHexColor = (str) => {
	return /[0-9A-Fa-f]{6}/g.test(str) && str.length === 6;
};

export const isValidEmail = (str) => {
	return str.match(regExValidator.email);
};

export const isDarkTheme = (themeType) => {
	return themeType === Constants.themeDarkString;
};

export const isDarkThemeWithObj = (theme) => {
	return theme.palette.mode === Constants.themeDarkString;
};

export const getWidgetIDFromTitle = (title) => {
	return scrubNonAlphaNumeric(title).toLowerCase();
};

export const isDeviceLargeFx = (theme) => theme.breakpoints.up("lg"); //lg=1600, so everything larger than that
export const isDeviceCompactFx = (theme) => theme.breakpoints.down("md"); //from 0 upto and md (1280)
export const isDeviceMobileFx = (theme) => theme.breakpoints.down("sm"); //from 0 upto and sm (800)

export const removeSpacesAndLowerCase = (inputString) => {
	return inputString.replace(/\s/g, "_").toLowerCase();
};

export const getFontColorFromBackground = (theColor, defaultColor) => {
	if (!theColor || theColor.length === 0) return defaultColor;

	let hex = theColor.toString().replace("#", "");

	if (hex.length !== 6) return defaultColor;

	let r = parseInt(hex.substr(0, 2), 16);
	let g = parseInt(hex.substr(2, 2), 16);
	let b = parseInt(hex.substr(4, 2), 16);

	let lum = (r * 299.0 + g * 587.0 + b * 114.0) / 1000.0;

	if (lum > 255.0 / 2) return "#000000";
	// bright colors - black font
	else return "#FFFFFF"; // dark colors - white font
};

export const getRandomString = (length, chars) => {
	let result = "";
	for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
	return result;
};

export const capitalizeFirstLetter = (str) => {
	if (!str || str.length === 0) return "";
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const toTitleCase = (str) => {
	str = str.toLowerCase().split(" ");
	for (let i = 0; i < str.length; i++) {
		str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
	}
	return str.join(" ");
};

export const toTitleCaseFiltered = (str) => {
	const articles = ["a", "an", "the"];
	const conjunctions = ["for", "and", "nor", "but", "or", "yet", "so"];
	const prepositions = [
		"with",
		"at",
		"from",
		"into",
		"upon",
		"of",
		"to",
		"in",
		"for",
		"on",
		"by",
		"like",
		"over",
		"plus",
		"but",
		"up",
		"down",
		"off",
		"near"
	];

	// The list of spacial characters can be tweaked here
	const replaceCharsWithSpace = (str) => str.replace(/[^0-9a-z&/\\]/gi, " ").replace(/(\s\s+)/gi, " ");
	const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.substr(1);
	const normalizeStr = (str) => str.toLowerCase().trim();
	const shouldCapitalize = (word, fullWordList, posWithinStr) => {
		if (posWithinStr === 0 || posWithinStr === fullWordList.length - 1) {
			return true;
		}

		return !(articles.includes(word) || conjunctions.includes(word) || prepositions.includes(word));
	};

	str = replaceCharsWithSpace(str);
	str = normalizeStr(str);

	let words = str.split(" ");
	if (words.length <= 2) {
		// Strings less than 3 words long should always have first words capitalized
		words = words.map((w) => capitalizeFirstLetter(w));
	} else {
		for (let i = 0; i < words.length; i++) {
			words[i] = shouldCapitalize(words[i], words, i) ? capitalizeFirstLetter(words[i], words, i) : words[i];
		}
	}

	return words.join(" ");
};

export const convertCommaStringIDsToArray = (inputString) => {
	let finalArray = [];
	if (!inputString) return [];

	let stringIds = inputString.split(",");
	if (stringIds && stringIds.length > 0)
		stringIds.forEach((strID) => {
			let intID = parseInt(strID) || 0;
			if (intID > 0 || intID < 0) {
				finalArray.push(intID);
			}
		});

	return finalArray;
};

export const convertCommaStringValsToArray = (inputString) => {
	let finalArray = [];
	let stringIds = inputString?.split(",");
	if (stringIds && stringIds.length > 0)
		stringIds.forEach((strVal) => {
			if (strVal && strVal.length > 0) {
				finalArray.push(strVal);
			}
		});

	return finalArray;
};

//from select
export const convertIDarrayToCommaString = (array, negativeNumber) => {
	if (!array || array.length === 0) return "";

	const multiplier = negativeNumber ? -1 : 1;

	let idsString = "";
	array.forEach((r) => {
		if (r.value && r.value !== 0) idsString = idsString + ((multiplier * parseInt(r.value) || "") + ",");
	});
	return idsString;
};

//from select
export const convertSelectObjectArrayToCommaString = (array) => {
	if (!array || array.length === 0) return "";

	let idsString = "";
	array.forEach((r) => {
		if (r.value && r.value !== 0 && r.value.trim().length > 0) idsString = idsString + r.value + ",";
	});
	return idsString;
};

export const maxStringLength = (thestring, thelength, includeElipse) => {
	const elipse = includeElipse ? "..." : "";
	if (thestring === undefined) return "";
	const trimmedString = thestring.length > thelength ? thestring.substring(0, thelength) + elipse : thestring;
	return trimmedString;
};

